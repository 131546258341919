:root {
    --theme-MainColor: #8405ff;
    --common-color: #8405ff;
    --cancel-button: #de1d1d;
    --common-button-bgcolor: #29a1e6;
    --common-button-txtcolor: #fff;
    --focus-input-color: #3ac451;
}


@font-face
{
    font-family: 'OpenSans-Regular';
    src: url('../fonts/OpenSans-Regular-webfont.ttf') ;
}
@font-face
{
    font-family: 'OpenSans-Bold';
    src: url('../fonts/OpenSans-Bold-webfont.ttf') ;
}
@font-face
{
    font-family: 'OpenSans-Semibold';
    src: url('../fonts/OpenSans-Semibold.ttf') ;
}
@font-face
{
    font-family: 'Avenier Regular';
    src: url('../fonts/AVENIRLTSTD-BOOK.OTF') ;
    /* src: url('../fonts/AvenirLTStd-Medium.otf') ; */
}
.theme-color {
  color: var(--theme-MainColor);
}
/* body {
    color: #0f0f10;
  }
  .bg-clr-wthHead header {
    background-color: #f8f8fc;
  }
  p,
  ul.ul-liike-p {
    font-size: 18px;
    color: #464646;
  }
  p.p-18 {
    font-size: 18px;
  }
  .font-20 {
    font-size: 20px;
  }
  h1.headd {
    font-weight: 400;
  }
  .color-maintext,
  .color-maintext:hover {
    color: var(--theme-MainColor);
    text-decoration: none;
  }
  .bg-grey {
    background-color: #f7f7f7;
  }
  .purpleWrap {
    float: left;
    width: 100%;
  }
  .bold-700,
  .h5-700 {
    font-weight: 700;
  }
  .purplecontactusbtn {
    margin-top: 3rem;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .w-40 {
    width: 40%;
  }
  .w-10 {
    width: 10%;
  }
  .purplehealthfeaturehead {
    text-align: center;
    font-size: 44px;
    font-weight: 100;
    padding: 53px 0 5px;
    width: 100%;
    line-height: 1.3;
  } */
  body .margin-0 {
    margin: 0 !important;
}
button:disabled,input[type="checkbox"]:disabled {
  opacity: 0.5;
  /* pointer-events: none; */
  cursor: no-drop;
}
.react-datepicker__year-dropdown {
  display: flex !important;
  flex-direction: column-reverse !important; /* Change to column-reverse to reverse the order */
}
.pharmacyapp-wrapper {
    width: 100%;
    font-family: "Poppins Light";
  }
  .pharmacyapp-wrapper,
  .pharmacyapp-wrapper p {
    color: #3f3d56;
    font-size: 12px;
  }
  .splash-banner {
    padding: 30px 0px;
    height: 100vh;
  }
  .splash-banner1 {
    background: linear-gradient(#FFBE22 , #D14E09);
  }
  .splash-banner2 {
    background: linear-gradient(#ED3A21 , #65150A);
  }
  .splash-banner3 {
    background: linear-gradient(#740CEF, #390075);
  }
  .splash-banner4 {
    background: linear-gradient(#3BCDDD , #1259D7);
  }
  .splash-banner5 {
    background: linear-gradient(#ffffff , #ffffff);
    color:#000;
  }
  .splash-banner5 .text-dark{
    color:#000;
  }
  .sp-banner-img {
    margin: 0 auto;
  }
  .sp-banner-content {
    width: 320px;
    margin: 10px auto 0;
    text-align: center;
  }
  .best_of{
    font-size: 16px;
    color:#000;
    font-family: "OpenSans-Regular";
    font-weight: 500;
  }
  .sp-banner-content-head{
    width: 320px;
    margin: 20px auto 0;
    text-align: center;
  }
  .pharmacyapp-splashheadMarginb{
    margin-bottom: 4px !important;
    text-transform: unset !important;
  }
  .pharmacyapp-splashhead-p{
    font-family: 'OpenSans-Semibold' !important;
  }
  .pharmacy-splash-btnmain {
    text-align: center;
    margin-top: 30px;
  }
  .pharmacy-splash-button {
    background-color: #2acb94;
    border-radius: 5px;
    color: #fff;
    border: 0;
    padding: 15px 15px;
    font-size: 12px;
    letter-spacing: 1.1px;
  }
  .pharmacy-splash-button-b {
    background-color: #3f3d56;
  }
  .pharmacy-splash-button:hover {
    color: #fff;
    text-decoration: none;
  }
  .pharmacy-splash-button-r {
    background-color: #CC3F1D;
    font-size: 13px;
    font-family: 'OpenSans-Bold';
    /* padding: 6px 10px !important; */
    padding: 9px 14px !important;
    border-radius: 10px !important;
  }
  .iosSpan{
    padding:12px;
    display: block;
  }
  .pharmacyapp-wrapper .sp-banner-content-p {
    color: #444444;
    font-size: 12px;
    font-weight: 300;
    /* text-transform: capitalize; */
    letter-spacing: 1px;
    padding-top: 2px;
    padding-left: 7px;
    font-family: "OpenSans-Regular";
    line-height: 19px;
    margin-bottom: 0.1rem;
  }
  .pharmacyapp-wrapper .slick-dots {
    bottom: 40px;
  }
  .pharmacyapp-wrapper .slick-dots li button:before {
    font-size: 10px;
    color: #000;
    opacity: 1;
  }
  .pharmacyapp-wrapper .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #a5a4a4;
  }
  .pharmacyapp-splashhead {
    color: #014fc4;
    font-size: 18px;
    text-align: center;
    margin-bottom: 16px;
    font-family: 'OpenSans-Bold';
  
  }
  .pharmacyapp-textField {
    width: 260px;
    border-radius: 10px;
    border: 1px solid #ddd;
    font-size: 14px;
    text-indent: 13px;
    padding: 8px 0;
    color: #757575;
    font-family: "OpenSans-Regular";    
    display: block;
    margin: 10px auto;
}
  .invite-network-wrapper {
    background-color: #e6e6e6;
    padding-top: 10px;
    position: relative;
    height: 100vh;
  }
  .invite-network-banner {
    padding: 0 20px;
    margin-top: 20px;
    height: 43vh;
  }
  .pharmacyapp-head {
    color: #3f3d56;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
  }
  .invite-network-img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
  .invite-network-dr-main {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    margin: 7vh 20px;
    height: auto;
  }
  .invite-network-dr-image {
    display: inline-block;
  }
  .invite-network-dr-name {
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    display: inline-block;
    vertical-align: middle;
    color: #3f3d56;
    margin-left: 5px;
  }
  .invite-network-dr-content,
  .invite-network-dr-content p {
    font-size: 12px;
  }
  .invite-network-dr-edit {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .invite-network-btn-main {
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    padding: 20px 20px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .pharmacy-input {
    resize: none;
    width: 99%;
    height: 60px;
    display: none;
    border-color: #c3c3c3;
  }
  .pharmacy-btn {
    background-color: #2acb94;
    color: #fff;
    display: none;
    border: 0;
    margin-top: 5px;
  }
  .pharmacy-editActive .pharmacy-input,
  .pharmacy-editActive .pharmacy-btn {
    display: block;
  }
  .pharmacy-editActive .pharmacy-content-p {
    display: none;
  }
  .invite-network-button {
    background-color: #2acb94;
    border-radius: 15px;
    color: #fff;
    border: 0;
    padding: 8px 20px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 2px;
  }
  .invite-network-button img {
    float: right;
    margin-top: 5px;
  }
  .invitation-img {
    width: 100%;
    object-fit: contain;
    padding: 25px;
  }
  .pharmacy-invitation-wrapper {
    padding: 0 20px;
  }
  .pharmacy-invitation-banner {
    margin-top: 20px;
  }
  .pharmacy-invitation-main {
    padding: 0 20px;
  }
  .pharmacyapp-store {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
  }
  .pharmacyapp-store-list {
    width: 150px;
    padding: 5px 20px 0 0;
  }
  .promomessage {
    color: green;
    font-size: 12px;
  }
  ul.sp-banner-content {
    /* list-style-image: url('https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/splash-list.png'); */
    text-align: left;
    margin: 12px auto;
    position: relative;
  }
  ul.sp-banner-content .imagesplash {
    position: relative;
  }
  ul.sp-banner-content .image1splash:before {
    position: absolute;
    display: block;
    content: '';
    text-indent: -999999px;
    left: 1.25em;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-image: url(https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/manage.png);
    background-size: contain;    
    top: 2px;    
    left: -25px;
  }
  ul.sp-banner-content .image2splash:before {
    position: absolute;
    display: block;
    content: '';
    text-indent: -999999px;
    left: 1.25em;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-image: url(https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/calander.png);
    background-size: contain;
    top: 2px;
    left: -25px;
  }
  ul.sp-banner-content .image3splash:before {
    position: absolute;
    display: block;
    content: '';
    text-indent: -999999px;
    left: 1.25em;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-image: url(https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/consultation.png);
    background-size: contain;
    top: 2px;
    left: -25px;
  }
  ul.sp-banner-content .image4splash:before {
    position: absolute;
    display: block;
    content: '';
    text-indent: -999999px;
    left: 1.25em;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-image: url(https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/prescription.png);
    background-size: contain;
    top: 2px;
    left: -25px;
  }
  ul.sp-banner-content .image5splash:before {
    position: absolute;
    display: block;
    content: '';
    text-indent: -999999px;
    left: 1.25em;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-image: url(https://s3.ap-south-1.amazonaws.com/wl.purplehealth.com/phImageFolder_v1/pharmacyApp/refer.png);
    background-size: contain;
    top: 2px;
    left: -25px;
  }
  .pharmacyapp-splash-freebtn{
  justify-content: center;
  border: none;
  color: #1259D7 ;
  font-size: 14px;
  font-family: 'OpenSans-Bold';
  background: #fff;
  border-radius: 7px;
  padding: 2px 10px;
  }
  .sp-banner-img-div{
  width: 330px;
  margin: 0 auto;
  background-color: #fff;
  /* padding-top: 15px; */
  border-radius: 40px 2%;
  }
  .sp-accept{
    background: #448f27;
    color: #fff !important;
    padding: 5px 40px;
    border-radius: 5px;
    font-size: 16px;
    font-family: "OpenSans-Regular";
    display: inline-block;
  }
  .mbl-img{
    display:none !important;
  }
  .sp-notNow-div{
  margin-top: 12px;
  cursor: pointer;
  }
  .sp-notNow-text{
  font-size: 13px;
  color: #fff !important;
  font-family: 'OpenSans-Bold';
  }
/*** Login ***/
.adminHspM-loginwrper{
    position: relative;
}
.adminHspM-loginOuter{
    text-align: center;
    padding: 45px 0;
}
.adminHspM-loginDiv{
    margin: 0 auto;
    width: 900px;
    padding:30px 0 0;
}
.adminHspM-loginDiv h2{
    font-size: 42px;
    font-weight: 700;
    color: var(--theme-MainColor);
}
.adminHspM-loginDiv p{
    font-size: 15px;
    padding-top: 10px;
    font-weight: 600;
}
.adminHspM-loginfield input{
    padding: 7px 20px;
    margin: 5px 0;
    font-size: 14px;
    width: 24%;
    border: 1px solid #c1c1c1;
    border-radius: 10px;
    /* color: #c1c1c1; */
    color: #545454;
}
.adminHspM-loginfield input:focus{
    border: 1px solid var(--theme-MainColor);
    outline: unset;
}
.adminHspM-loginfield label{
    width: 82px;
    font-size: 15px;
    color: #000 ;
    font-weight: 600;
    text-align: left;
}
.adminHspM-loginforgot{
    text-align: center;
    padding: 20px 0;
    
}
.adminHspM-loginforgot a{
    font-size: 15px;
    color: var(--theme-MainColor);
    font-weight: 500;
}
.adminHspM-loginbtn{
    padding: 8px 25px;
    background-color: var(--theme-MainColor);
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.adminHspM-loginbtndiv{
  margin-top: 30px;
}
.adminHspM-loginbtn:focus{
  outline: unset;
}
  /*** End Login ***/

  /*** Dashboard ***/

  .admindashbord-Outer{
    margin: 50px 0;
    text-align: center;
    position: relative;
    min-height: 400px;
  }
  .admindashbord-Div{
    border: 1px solid #ddd;
    border-radius: 7px;
    padding: 50px 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
  }
  .sectionTag-admindash{
    border-radius: 5px;
    padding: 22px 25px;
    color: #616161;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
    box-shadow: 0 0 7px #ccc;
    font-weight: 600;
    background: #fff;
    font-size: 16px;
    margin: 20px 10px;
  }
  .dashboardAdminOuter{
    position: relative;
  }
  .backArowdImgdiv{
    position: absolute;
    top: -42px;
  }
  .backArowdImgdiv a {
    padding: 5px 0px;
    border-radius: 8px;
    color: #8400ff;
    font-size: 14px;
    font-weight: 600;
}
.admin-dashBtns:hover a {
  color: #fff;
}

.admin-dashBtns:hover {
  background-color: var(--theme-MainColor);
  color: #FFF;
}
.backArowdImgdiv span {
  font-size: 16px;
  color: #8400ff;
}
.backArowdImgdiv a:hover{
  text-decoration: unset;
}
.sectionItemDiv-dashboard{
  border: 1px solid #ddd;
  border-radius: 7px;
  margin: 100px 0;
  text-align: center;
  position: relative;
  min-height: 400px;
}
.adminMangemnt-dashboard{
  padding: 30px 30px;
  margin: 0 auto;
}
.admin-dashBtns {
  width: 24%;
  display: inline-block;
  padding: 6px 10px;
  border: 2px solid var(--theme-MainColor);
  border-radius: 10px;
  margin: 10px;
}
.admin-dashBtns a {
  color: var(--theme-MainColor);
  text-decoration: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
}
.breadcrum-adminMangmnt {
  border-style: none;
  margin-top: 5%;
  border-color: #f5f5f5 #e5e5e5 #CCC;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  list-style: outside none none;
  padding-left: 0;
}

.breadcrum-adminMangmnt li:first-child a {
  padding-left: 2em;
  border-radius: 5px 0 0 5px;
}

.breadcrum-adminMangmnt a {
  padding: 6px 20px;
  float: left;
  text-decoration: none;
  color: black;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
  background-color: #ddd;
  background-image: linear-gradient(to right, #f5f5f5, #ddd);
}
.breadcrum-adminMangmnt a::before {
  border-left-color: #ccc;
  right: -1.1em;
  z-index: 1;
}

.breadcrum-adminMangmnt a::after,
.breadcrum-adminMangmnt a::before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1.5em;
  border-top: 1.5em solid transparent;
  border-bottom: 1.5em solid transparent;
  border-left: 1em solid;
  right: -1em;
}

.breadcrum-adminMangmnt a::after {
  z-index: 2;
  border-left-color: #ddd;
}

.breadcrum-adminMangmnt .current,
.breadcrum-adminMangmnt .current:hover {
  font-weight: bold;
  background: 0;
  color: var(--theme-MainColor);
}

.breadcrum-adminMangmnt .current::after,
.breadcrum-adminMangmnt .current::before {
  content: normal;
}
.breadcrum-adminMangmnt .current{
  cursor: auto;
}

.breadcrum-adminMangmnt a::before {
  border-left-color: #ccc;
  right: -1.1em;
  z-index: 1;
}
.admin-dc-dashBtns {
  width: 22%;
  display: inline-block;
  padding: 9px 10px;
  border: 2px solid var(--theme-MainColor);
  border-radius: 10px;
  margin: 3px;
}
.admin-dc-dashBtns a {
  color: var(--theme-MainColor);
  text-decoration: none;
  background-color: transparent;
  font-size: 15px;
  font-weight: 600;
}
.adminMangemntdc-dashboard {
  padding: 30px 0px;
  width: 950px;
  margin: 0 auto;
}

 /*** End Dashboard ***/


  /*** start journal List ***/
.addNew-btn-journel{
  color: #333;
    text-decoration: none;
    line-height: 100%;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin: 0 2px 5px 0;
    background-color: #ececec;
    border: solid 1px #b8b8b9;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 30%), 0 1px 0 rgb(0 0 0 / 10%);
    width: auto;
    height: 30px;
    text-indent: -26px;
    padding: 8px 36px 6px 33px;
    background-image: url("../image/icons/addbtn.png");
    background-repeat: no-repeat;
    background-position: right center!important;
    font-size: 14px;
    font-weight: 700;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-bordered-hsp {
  border: 1px solid #ddd;
  border-collapse: separate;
  border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 0;
}

.table-bordered-hsp th,
.table-bordered-hsp td {
  border-left: 1px solid #ddd;
  padding: 15px;
  line-height: 20px;
  text-align: center;
  border-top: 1px solid #ddd;
  word-wrap: break-word;
}

.table-bordered-hsp th {
  font-weight: 700;
  background-color: #f2f2f2;
  vertical-align: middle!important;
}

.tablehead-hspmng {
  word-wrap: break-word!important;
}
.table-bordered-hsp tbody{
  font-size: 14px;
}

.breadcrum-adminMangmnt {
  border-style: none;
  margin-top: 20px;
  border-color: #f5f5f5 #e5e5e5 #CCC;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  list-style: outside none none;
  padding-left: 0;
}

.breadcrum-adminMangmnt li:first-child a {
  padding-left: 2em;
  border-radius: 5px 0 0 5px;
}

.breadcrum-adminMangmnt a {
  padding: 6px 20px;
  float: left;
  text-decoration: none;
  color: black;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
  background-color: #ddd;
  background-image: linear-gradient(to right, #f5f5f5, #ddd);
}

.breadcrum-adminMangmnt a::before {
  border-left-color: #ccc;
  right: -1.1em;
  z-index: 1;
}

.breadcrum-adminMangmnt a::after,
.breadcrum-adminMangmnt a::before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1.5em;
  border-top: 1.5em solid transparent;
  border-bottom: 1.5em solid transparent;
  border-left: 1em solid;
  right: -1em;
}

.breadcrum-adminMangmnt a::after {
  z-index: 2;
  border-left-color: #ddd;
}

.breadcrum-adminMangmnt .current,
.breadcrum-adminMangmnt .current:hover {
  font-weight: bold;
  background: 0;
  color: var(--theme-MainColor);
}

.breadcrum-adminMangmnt .current::after,
.breadcrum-adminMangmnt .current::before {
  content: normal;
}
.breadcrum-adminMangmnt .current{
  cursor: auto;
}

.breadcrum-adminMangmnt a::before {
  border-left-color: #ccc;
  right: -1.1em;
  z-index: 1;
}
.journlHead{
  font-size: 24px;
  font-weight: 700;
  padding: 23px 0 0px;
}
  /*** end journal List ***/

    /*** start add journal ***/
.rdw-editor-wrapper {
  box-sizing: content-box;
 }
 .redactor-wrapper {
  border: 1px solid #dcdcdc;
  min-height: 300px;
}
.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
}
.rdw-inline-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-option-wrapper {
  border: 1px solid #F1F1F1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.redactor-wrapper img {
  max-height: 300px;
}
.rdw-block-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-block-dropdown {
  width: 110px;
}
.rdw-dropdown-wrapper {
  height: 30px;
  background: white;
  cursor: pointer;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  margin: 0 3px;
  text-transform: capitalize;
  background: white;
}
.rdw-dropdown-selectedtext {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 5px;
}
.redactor-wrapper .rdw-dropdown-selectedtext, .redactor-wrapper .rdw-dropdown-selectedtext:hover {
  color: #000;
  text-decoration: none;
}
.rdw-dropdown-carettoopen {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-top: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.rdw-fontsize-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-fontsize-dropdown {
  min-width: 40px;
}
.rdw-dropdown-wrapper {
  height: 30px;
  background: white;
  cursor: pointer;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  margin: 0 3px;
  text-transform: capitalize;
  background: white;
}
.rdw-fontfamily-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-fontfamily-dropdown {
  width: 115px;
}
.rdw-fontfamily-placeholder {
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rdw-list-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-text-align-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-colorpicker-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-link-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-option-disabled {
  opacity: 0.3;
  cursor: default;
}
.rdw-embedded-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-emoji-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-remove-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-history-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
.DraftEditor-root {
  position: relative;
}
.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}
.DraftEditor-editorContainer {
  background-color: rgba(255,255,255,0);
  border-left: .1px solid transparent;
  position: relative;
  z-index: 1;
}
.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}
.public-DraftEditor-content[contenteditable=true] {
  -webkit-user-modify: read-write-plaintext-only;
}
.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}
.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}
.public-DraftStyleDefault-block {
  position: relative;
  white-space: pre-wrap;
}
.public-DraftStyleDefault-block {
  margin: 1em 0;
}
.form-section-brdr-jounal{
  border: 1px solid #dedede;
  border-radius: 20px;
  padding: 10px;
  margin: 20px 0;

}
.form-head-journal{
  font-size: 24px;
  font-weight: 600;
}
.input-design-journal{
  border: 1px solid #dcdcdc;
  background-color: #fff;
  width: 100%;
  padding: 0px;
  resize: none;
  border-radius: 6px;
  color: #989898;
  margin-bottom: 15px;
}
.input-design-journal:focus{
  outline: unset;
}
.label-text-journal{
  font-size: 16px;
  font-weight: 600;
}
.star-red-label{
  color: red;
}
.fileUpload-medical-journal, .fileUpload-medical-journal:hover {
  position: relative;
  overflow: hidden;
  background: transparent;
  border: 1px solid var(--theme-MainColor);
  text-align: center;
  padding: 5px 10px;
  border-radius: 8px;
  color: var(--theme-MainColor);
  height: auto;
  width: auto;
  float: left;
  font-weight: 500;
  cursor: pointer;
  min-width: 140px;
  margin-top: 14px;
  font-size: 14px;
}
.fileUpload-medical-journal input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.fileUpload-img {
  margin-top: 14px;
}
.note-journlimg{
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}
.detldes-div{
  padding: 10px 15px;
}
.formButton-save {
  color: var(--theme-MainColor);
  padding: 5px 25px;
  border-radius: 10px;
  text-transform: capitalize;
  border: 1px solid var(--theme-MainColor);
  margin: 10px 10px;
  background-color: #fff;
  text-decoration: none;
  font-size: 14px;
  min-width: 140px;
  font-weight: 600;
}
.formButton-cancel {
  color: red;
  padding: 5px 25px;
  border-radius: 10px;
  text-transform: capitalize;
  border: 1px solid red;
  margin: 10px 10px;
  background-color: #fff;
  text-decoration: none;
  font-size: 14px;
  min-width: 140px;
  font-weight: 600;

}
.formButton-divright{
  text-align: right;
}
.videocall_div{
  background: #fff;
  width: 400px;
  border-radius: 5px;
  margin: 0 auto;
  border: 1px solid rgba(0,0,0,.2);
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.videocall_footer button{
    border: 0;
    color: #fff;
    padding:8px 15px;
    border-radius: 3px;
    margin-right: 10px;
    border: 1px solid transparent;
    transition: .5s;
}
.videocall_footer button:last-child{
  margin-right: 0px;
}
.bttn_ok{
   background: #5482C7;
}
.bttn_ok:hover{
  border: 1px solid #0D6EFD;
  background: #fff;
  color: #000;
}
.videocall_body{
  padding: 10px 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.videocall_footer{
    padding: 4px 15px 15px;
    text-align: right;
    border-top: 1px solid rgb(146 135 135 / 10%);
}
.videocall_header{
    padding: 6px 15px;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid rgb(146 135 135 / 10%);
    background: #5482C7;
    color: #fff;
    border-radius: 5px 5px 0px 0px;
}
.btn_tab{
  color: #FFF;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px 15px;
  white-space: nowrap;
  border: 1px solid rgba(0,0,0,.1);
  text-align: center;
  font-weight: 600;
  margin-right: 5px;
  color: #555;
  outline: 0 !important;
  box-shadow: none;
}
.btn_tab.active{
  background-color: var(--theme-MainColor);
  border-color: var(--theme-MainColor);
  color: #fff;
}
.table_custom th{
  min-width: 200px;
}
.edit_view:hover{
  cursor: pointer;
  filter: opacity(0.5);
}
.modal_overlay .content{
  height: 100%;
  overflow-y: auto !important;
}
.closepopupqual img:hover{
  filter: contrast(0.5);
}
.nodata_found{
  text-align: center;
  border: 1px solid #ddd;
  padding: 20px 0px;
  color: red;
  font-size: 18px;
}
.span_12 div span,.journl-des-txt span{
  font-size: 14px;
}
.terms-head, .terms-subhead {
  font-weight: 500;
  text-transform: uppercase;
}
.terms-head {
  text-align: center;
  font-size: 36px;
}
.terms-subhead {
  font-size: 26px;
  margin-bottom: 15px;
}
body .terms-wrapper .terms-p-bold {
  font-weight: 600;
}
body .terms-wrapper-popup p {
  font-size: 14px;
  line-height: 23px;
}
body .terms-wrapper-popup ul {
  padding-left: 20px;
}
.modalinput_show{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.3);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalinput_inner{
  width: 400px;
  height: auto;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 22%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  padding-bottom: 10px;
}
.modal_informationfooter{
  text-align: right;
}
.modal_informationfooter input{
  margin-top: 10px;
  margin-bottom: 0px;
}
.close_modalinput img{
  width: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.modal_innerform{
  margin-bottom: 8px;
}
.modal_innerform label{
  font-weight: bold;
  margin-bottom: 3px;
}
.freecms_banner{
  background-color: #7715f5;
  color: #fff;
  position: relative;
}
.freecms_container{
  /* max-width: 85%;
  margin: auto; */
  display: flex;
  position: relative;
  /* z-index: 99; */
  padding-top: 78px;
  padding-bottom: 228px;
  flex-wrap: wrap;
}
.freecms_sliderleft{
  width: 65%;
  padding-right: 102px;
  padding-bottom: 80px;
}
.freecms_sliderright{
  width: calc(100% - 65%);
  position: relative;
  z-index: 99;
}
.freecms_sliderhead{
  font-size: 46px;
  line-height: 1.3;
  font-family: sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
}
.freecms_slidersubhead{
  font-size: 30px;
  font-family: sans-serif;
  font-weight: 600;
}
.freecms_sliderpara{
  font-size: 30px;
  padding: 20px 0;
}
.freecms_input input{
    width: 100%;
    margin-bottom: 30px;
    padding: 18px 10px;
    border-radius: 10px;
    border: 0;
    outline: 0;
    box-shadow: none;
    font-size: 20px;
    text-align: center;
    color: #9d9d9d;
    font-family: 'OpenSans-Bold';
}
.freecms_input input::placeholder{
  color: #9d9d9d;
}
.freecms_input button,.freecms_joinnow{
  width: 100%;
  background-color: #f9db57;
  border-radius: 10px;
  border: 0;
  outline: 0;
  box-shadow: none;
  padding: 12px 10px;
  font-weight: 600;
  font-size: 28px;
}
.freecms_joinnow{
  width: auto;
  min-width: 25%;
  padding: 10px 10px;
  margin-top: 80px;
}
.freecms_required{
  text-align: right;
  margin-top: 30px;
  color: #9d9d9d;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.freecms_bg,.freecms_left_user{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.freecms_left_user{
  width: 500px;
  z-index: 99;
}
.freecms_bg img{
  width: 100%;
}
.freecms_left_user img{
  max-width: 100%;
      margin: auto;
    display: block;
}
.freecms_features{
  background-color: #f8f6ff;
  text-align: center;
  padding: 100px 50px;
}
.freecms_featureshead{
  font-size: 45px;
  font-family: sans-serif;
  font-weight: 600;
  margin-bottom: 25px;
  letter-spacing: 0px;
  color: #5e5e5e;
}
.freecms_features_key{
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  margin: auto;
  margin-bottom: 30px;
  width: 475px;
}
.freecms_features_img{
  width: 50px;
}
.freecms_features_img img{
  max-width: 100%;
  filter: brightness(0);
}
.freecms_features_connt{
  width: 425px;
  padding-left: 45px;
}
.freecms_apponint_head{
  font-size: 22px;
  color: #000;
  margin-bottom: 7px;
  font-family: sans-serif;
  font-weight: 800;
}
.freecms_apponint_para{
  font-size: 20px;
  font-family: 'OpenSans-Semibold';
  line-height: 1.2;
  color: #000;
}
.freecmstwo .freecms_left_user{
  left: unset;
  right: 0;
}
.freecmstwo .freecms_sliderleft{
  width: 100%;
  padding-right: 0;
}
.freecmstwo .freecms_sliderpara{
  width: 55%;
  padding: 30px 0;
}
.freecmstwo .freecms_container{
  padding-bottom: 50px;
}
.freecmstwo .freecms_features{
  padding: 50px 0px;
}
.freecms_innercard .freecms_features_connt{
  width: calc(100% - 50px);
}
.freecms_innercard{
  background: #fff;
  border-radius: 50px;
  padding: 20px 20px 20px 50px;
}
.freecms_editfeathead{
  font-size: 25px;
}
.freecms_editinput input{
  border: 2px solid #ccc;
}
.freecms_editrequired{
  margin: 20px 0 40px;
}
.freecms_inr_inner{
  font-size: 80px;
  position: relative;
  font-family: sans-serif;
  font-weight: 500;
  margin-bottom: 35px;
}
.freecms_inr_inner sub{
  font-size: 22px;
  margin-left: 10px;
  font-family: 'OpenSans-Semibold';
}
.freecms_inr_inner sup{
  font-size: 22px;
  vertical-align: revert;
  margin-right: 10px;
  font-family: 'OpenSans-Semibold';
}
.freecms_editinput button{
  margin-top: 20px;
}
.feecms_editinnercard{
  background-color: transparent;
  border-radius: 0;
}
.freecms_innercard .freecms_features_key{
  width: 100%;
}
.feecms_editinnercard .freecms_featureshead{
  font-size: 40px;
}
.freecms_billspan{
  display: block;
  text-align: right;
  font-size: 16px;
  margin-right: 38px;
}
.freecms_featureshead.freecms_freeforever {
  color: #000;
  margin-top: 50px;
  font-size: 48px;
}
.freecms_editinput{
  padding: 0px 50px;
}
.freecms {
  color: #000;
  font-family: sans-serif;
}
.freecms_apponint_para ul{
  padding-left: 20px;
}
.vid-aud-rprs-p {
  padding: 5px 0;
  border-bottom: 1px dashed #c3c3c3;
  margin-bottom: 0;
}
.vid-aud-rprs-p:nth-last-child(1) {
  border: 0;
}
.main-wrapper .vid-aud-rprs-p, .main-wrapper1 .vid-aud-rprs-p {
  font-size: 12px;
}
.vid-aud-rprs-p span {
  display: block;
  white-space: nowrap;
}
.react-stars span{
  font-size:30px !important;
}
.feedback-form{
  text-align: left;
  width:500px;
  margin: 0 auto;
}
.ratingFlex{
  display: flex;
  justify-content: center;
}
.sitesetng-svebtn.btn-popup{
  padding: 6px 25px;
  margin: 7px 0px;
}
.searchbtn-hspMng.export_excel{
  width:120px
}
.searchDropdowns .option{
  padding: 5px 10px;
  border-bottom:1px solid #ccc;
}
.sp-banner-p{
  font-family: "OpenSans-Regular";
  margin-bottom:5px;
}
.sp-banner-p-color{
  color:#0052ca;
  font-family: 'OpenSans-Bold';
}
.feature-apphead{
  font-family: 'OpenSans-Semibold';
}
.splashBlue{
  color: #024fc3;
  font-family: 'OpenSans-Bold';
}
.countList{
  float: right;
  font-size:12px;
}
.doctorWork-dashboard {
    border: 1px solid #ddd;
    border-radius: 7px;
    margin: 30px 0;
    text-align: center;
    position: relative;
    min-height: 400px;
}

.head-doctorworkList {
    background-color: #FFF;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    line-height: 24px;
    clear: both;
    text-align: justify;
    padding-top: 4px;
    margin-bottom: 1%;
}

.AddNewdoctrworkDiv {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.AddNewdoctrbtnDiv {
    font-size: 14px;
    font-weight: 700;
}

.AddNewdoctrbtnDiv:focus {
    outline: unset;
}

.AddNewdoctrbtnDiv {
    color: #333;
    text-decoration: none;
    line-height: 100%;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin: 0 2px 5px 0;
    background-color: #ececec;
    border: solid 1px #b8b8b9;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 30%), 0 1px 0 rgb(0 0 0 / 10%);
    width: auto;
    height: 30px;
    text-indent: -26px;
    padding: 8px 36px 6px 33px;
    background-image: url("../image/icons/addbtn.png");
    background-repeat: no-repeat;
    background-position: right center!important;
}

.tableHeader-HspMangemnt {
    width: 100%;
    height: 50px;
    background-color: #e0e0e0;
    padding: .6%;
    border: 1px solid #dcdcdc;
    clear: both;
    margin-bottom: 10px;
}

.selectField-HspMangmnt {
    float: left!important;
    margin-right: 1%!important;
}

.slecthsp {
    padding: 2px;
    width: 145px;
    font-family: Helvetica, sans-serif;
    font-size: 13px;
    margin: 1px 0px 0px;
    border: 1px solid lightgray;
    height: 32px;
    color: #8f8f8f;
    outline: unset;
}

.hspmngInput {
    padding: 1px;
    width: 145px;
    font-family: Helvetica, sans-serif;
    font-size: 14;
    margin: 1px 0px 0px;
    border: 1px solid lightgray;
    height: 32px;
    text-indent: 5px;
    color: #8f8f8f;
    outline: unset;
}

.searchbtn-hspMng {
    background-color: var(--theme-MainColor);
    border-color: var(--theme-MainColor);
    color: #FFF;
    border-radius: 1px;
    display: inline-block;
    font-size: 14px;
    padding: 5px;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid transparent;
    cursor: pointer;
    text-align: center;
    margin-right: 1%;
    width: 80px;
    font-weight: 600;
}

.searchbtn-hspMng:focus {
    outline: unset;
}

.table-doctrworkList th {
    font-size: 16px;
}

.table-doctrworkList td {
    font-size: 14px;
}

.qualifcatin-popuplabel {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 160px;
    padding-top: 5px;
    font-size: 14px;
}

.qualifcatin-popupInput {
    padding: 1px;
    width: 233px;
    font-family: Helvetica, sans-serif;
    font-size: 1.2em;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 4px 5px;
    text-indent: 5px;
    color: #8f8f8f;
    float: left;
    font-size: 13px;
}

.language-popuptextarea {
    padding: 1px;
    width: 233px;
    font-family: Helvetica, sans-serif;
    font-size: 1.2em;
    border: 1px solid #d3d3d3;
    height: 60px;
    margin: 0 4px 5px;
    text-indent: 5px;
    color: #8f8f8f;
    float: left;
    font-size: 13px;
}

.popupdiv {
    /* width: 422px !important; */
    clear: both;
}


/* .AddNewdoctrworkDiv .popup-content{
    width: 40% !important;
    top: 20% !important;
    left: 0 !important;
    right: 0;
    margin: 0 auto;
    z-index: 9999 !important;
}
.AddNewdoctrworkDiv .popup-overlay{
    background: rgba(0,0,0,.5);
    z-index: 999;
}
.AddNewdoctrworkDiv  .popup-arrow{
    display: none;
}  */

.popuphead-qualifctn {
    height: 30px;
    position: initial;
    margin-bottom: 6%;
    border-bottom: 1px solid #f2f2f2;
    margin-top: 2%;
}

.popuphead-qualifctn-bottom {
    margin-bottom: 3%;
}

.popuphead-qualifctn h2 {
    font-size: 17px;
}

.qual-checkbox {
    margin-top: 10px;
    margin-left: 4px;
}

.butnsave-qual {
    background-color: var(--theme-MainColor);
    border: none;
    padding: 5px 28px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
}

.butncancel-qual {
    background-color: #adadad;
    border: none;
    padding: 5px 25px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin: 10px;
}

.star-red-qual {
    color: red;
}

.closepopupqual {
    position: absolute;
    top: 8px;
    right: 17px;
    cursor: pointer;
}

.popupbutndiv {
    clear: both;
    text-align: center;
}

.popupdiv input {
    outline: unset;
}

.popupbutndiv button:focus {
    outline: unset;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    margin-bottom: 0;
}

.pagination>li {
    display: inline;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 7px 6px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #000;
    border: 1px solid #ddd;
    margin-left: -1px;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    text-align: center;
    margin: 0 2px;
    background-color: #e2e2e2;
}

.pagination-qualList {
    text-align: right;
    width: 70%;
    display: inline-block;
}

.pagination-qualList ul li a {
    border-radius: 13%;
    height: 34px;
    width: 34px;
    font-size: 13px;
    font-weight: 900;
}

.pagination-qualList ul li a:hover {
    background-color: var(--theme-MainColor);
    color: #fff;
}

.pagination-qualList ul li a:focus {
    background-color: var(--theme-MainColor) !important;
    color: #fff;
}

.pagination-qualList ul li a:active {
    background-color: var(--theme-MainColor) !important;
    color: #fff;
}

.pagination-qualList ul .active a {
    background-color: var(--theme-MainColor) !important;
    color: #fff;
}

.qual-popupdivouter {
    width: 30%;
    display: inline-block;
    vertical-align: top;
}

.pagination-qualListwidth {
    width: 100% !important;
}

.popBack {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 5;
    overflow-y: auto;
}

.popOverlay {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.customizationPop {
    background-color: #fff;
    width: 40%;
    margin: 15px auto;
    border-radius: 5px;
    z-index: 1;
    position: absolute;
    padding: 15px;
    left: 0;
    right: 0;
}

.customizationPopLarge {
    width: 80%;
}

.editbtn-qual {
    cursor: pointer;
}


/* ================================ ADMIN CSS BEGINS ======================================= */

.sectionBorderadminMangmnt {
    border: 1px solid #ddd;
    border-radius: 7px;
}

.adminMangemnt-dashboard {
    padding: 30px 30px;
    margin: 0 auto;
}

.admin-dashBtns {
    width: 24%;
    display: inline-block;
    padding: 6px 10px;
    border: 2px solid var(--theme-MainColor);
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
}

.admin-dashBtns a {
    color: var(--theme-MainColor);
    text-decoration: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
}

.admin-dashBtns:hover a {
    color: #fff;
}

.admin-dashBtns:hover {
    background-color: var(--theme-MainColor);
    color: #FFF;
}

.admin-dc-dashBtns {
    width: 22%;
    display: inline-block;
    padding: 9px 10px;
    border: 2px solid var(--theme-MainColor);
    border-radius: 10px;
    margin: 3px;
}

.admin-dc-dashBtns a {
    color: var(--theme-MainColor);
    text-decoration: none;
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
}

.admin-dc-dashBtns:hover a {
    color: #fff;
}

.admin-dc-dashBtns:hover {
    background-color: var(--theme-MainColor);
    color: #FFF;
}

.adminMangemntdc-dashboard {
    padding: 30px 0px;
    width: 950px;
    margin: 0 auto
}

.breadcrum-adminMangmnt {
    border-style: none;
    margin-top: 5%;
    border-color: #f5f5f5 #e5e5e5 #CCC;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    list-style: outside none none;
    padding-left: 0;
}

.breadcrum-adminMangmnt li:first-child a {
    padding-left: 2em;
    border-radius: 5px 0 0 5px;
}

.breadcrum-adminMangmnt a {
    padding: 6px 20px;
    float: left;
    text-decoration: none;
    color: black;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
    background-color: #ddd;
    background-image: linear-gradient(to right, #f5f5f5, #ddd);
}

.breadcrum-adminMangmnt a::before {
    border-left-color: #ccc;
    right: -1.1em;
    z-index: 1;
}

.breadcrum-adminMangmnt a::after,
.breadcrum-adminMangmnt a::before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    border-top: 1.5em solid transparent;
    border-bottom: 1.5em solid transparent;
    border-left: 1em solid;
    right: -1em;
}

.breadcrum-adminMangmnt a::after {
    z-index: 1;
    border-left-color: #ddd;
}

.breadcrum-adminMangmnt .current,
.breadcrum-adminMangmnt .current:hover {
    font-weight: bold;
    background: 0;
    color: var(--theme-MainColor);
}

.breadcrum-adminMangmnt .current::after,
.breadcrum-adminMangmnt .current::before {
    content: normal;
}

.breadcrum-adminMangmnt .current {
    cursor: auto;
}

.breadcrum-adminMangmnt a::before {
    border-left-color: #ccc;
    right: -1.1em;
    z-index: 1;
}

.head-hospMangmntList {
    background-color: #FFF;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    line-height: 24px;
    clear: both;
    text-align: justify;
    padding-top: 4px;
    margin-bottom: 1%;
}

.addnewhspbtn {
    color: #333;
    text-decoration: none;
    line-height: 100%;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin: 0 2px 5px 0;
    background-color: #ececec;
    border: solid 1px #e0e0e0;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 30%), 0 1px 0 rgb(0 0 0 / 10%);
    width: auto;
    height: 30px;
    text-indent: -26px;
    padding: 8px 36px 6px 33px;
    background-image: url("../image/icons/addbtn.png");
    background-repeat: no-repeat;
    background-position: right center!important;
    cursor: pointer;
}


/* .tableHeader-HspMangemnt {
    width: 100%;
    height: 50px;
    background-color: #e0e0e0;
    padding: .6%;
    border: 1px solid #dcdcdc;
    clear: both;
    margin-bottom: 10px;
} */


/* .selectField-HspMangmnt {
    float: left!important;
    margin-right: 1%!important;
} */


/* .slecthsp {
    padding: 2px;
    width: 145px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    margin: 1px 0px 0px;
    border: 1px solid lightgray;
    height: 32px;
    color: #8f8f8f;
} */


/* .hspmngInput {
    padding: 1px;
    width: 145px;
    font-family: Helvetica, sans-serif;
    font-size: 14;
    margin: 1px 0px 0px;
    border: 1px solid lightgray;
    height: 32px;
    text-indent: 5px;
    color: #8f8f8f;
} */


/* .searchbtn-hspMng {
    background-color: var(--theme-MainColor);
    border-color: var(--theme-MainColor);
    color: #FFF;
    border-radius: 1px;
    display: inline-block;
    font-size: 14px;
    padding: 5px;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid transparent;
    cursor: pointer;
    text-align: center;
    margin-right: 1%;
    width: 80px;
    font-weight: 800;
} */

.table-bordered-hsp {
    border: 1px solid #ddd;
    border-collapse: separate;
    border-collapse: collapse;
    border-left: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 0;
    /* table-layout: fixed; */
}

.table-bordered-hsp th,
.table-bordered-hsp td {
    border-left: 1px solid #ddd;
    padding: 8px;
    line-height: 26px;
    text-align: left;
    border-top: 1px solid #ddd;
    word-wrap: break-word;
}

.table-doctrworkListBorder th,
.table-doctrworkListBorder td,
.table-doctrworkListBorder td.borderNone {
    border: 0;
}

.table .table_inside th,
.table .table_inside td {
    padding: 0;
    border: 0;
}

.table_inside tbody,
.table_insideInner {
    border-bottom: 1px solid #545454;
}

.table_inside {
    width: 100%;
    display: flex;
    align-items: center;
}

.table-bordered-hsp th {
    font-weight: 700;
    background-color: #f2f2f2;
    vertical-align: middle!important;
    font-size: 14px;
}

.tablehead-hspmng {
    word-wrap: break-word!important;
}

.listLink-hspmangmnt {
    font-size: 12px;
    font-weight: 500;
    color: #09F!important;
    line-height: 30px;
    vertical-align: super;
    cursor: pointer;
    text-decoration: underline!important;
    white-space: nowrap;
}

.table-bordered-hsp tr:nth-child(even) {
    background-color: #fff !important;
}

.smssetngsWrap {
    width: 980px;
    margin: 30px auto;
    padding: 35px 20px;
    /* border: 1px solid #ddd; */
    overflow: hidden;
    clear: both;
}

.smssetngsTop {
    width: 500px;
    margin: auto;
    padding-bottom: 30px;
    clear: both;
    overflow: hidden;
}

.smssetngsTop label {
    padding-right: 15px;
    width: 130px;
    float: left;
    padding-top: 5px;
    font-size: 15px;
}

.smssetngsTop select {
    width: 250px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    height: 36px;
    color: #8f8f8f;
    padding: 0 5px;
    float: left;
}

.smsSetngHead {
    clear: both;
    overflow: hidden;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    color: #337ab7;
    font-weight: 600;
    font-size: 15px;
    text-align: left;
}

.smsSetngsContent {
    padding: 30px 0;
}

.smssetngscontentdiv {
    width: 50%;
    float: left;
    padding: 0 15px;
}

.smssetngscontentdiv .smsItem {
    overflow: hidden;
    clear: both;
    margin-bottom: 10px;
}

.smssetngscontentdiv .smsItem label {
    float: left;
    font-size: 14px;
    width: 240px;
    padding-top: 7px;
    text-align: right;
    padding-right: 15px;
    margin: 0;
}

.smssetngscontentdiv .smsItem input[type="text"],
.smssetngInput {
    width: auto;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    height: 36px;
    color: #8f8f8f;
    padding: 0 5px;
    float: left;
    width: 180px;
    border-radius: unset;
}

.smssetngscontentdiv .smsItem input[type="text"]:focus,
.smssetngsTop select:focus {
    border: 1px solid #000;
}

.smssetngscontentdiv.smssetngCheckbox {
    width: 100%;
}

.smssetngscontentdiv .smsItem input[type="checkbox"] {
    width: 18px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    height: 28px;
    color: #8f8f8f;
    padding: 0 5px;
    float: left;
}

.smssetngsWrap .smssetng-btn {
    padding: 15px 15px 0 0;
    float: right;
    clear: both;
}

.smssetng-svebtn {
    background-color: var(--theme-MainColor);
    font-size: 14px;
    padding: 9px 25px;
    text-decoration: none;
    /* width: 80px; */
    display: inline-table;
    text-align: center;
    color: #fff !important;
    cursor: pointer;
    margin: 5px;
    border: 0;
    font-weight: 600;
}

.smssetng-cancelbtn {
    background-color: #adadad;
    font-size: 14px;
    padding: 9px;
    text-decoration: none;
    border: 0;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    width: 80px;
    font-weight: 600;
}
.smssetng-resetbtn {
  background-color: #c32e2e;
  font-size: 14px;
  padding: 9px;
  text-decoration: none;
  border: 0;
  color: #fff;
  cursor: pointer;
  margin: 5px;
  width: 80px;
  font-weight: 600;
}
.smssetngscontentdiv .smsItem .react-datepicker-wrapper {
    display: block;
}

.smssetngscontentdiv .smsItem .react-datepicker__input-container {
    position: relative;
    display: block;
    width: auto;
}

.smssetngscontentdiv .smsItem .react-datepicker-popper {
    top: 3% !important;
    left: 19% !important;
}


/* .smssetngscontentdiv .smsItem .react-datepicker__triangle{
    display: none;
} */

.hspInformation-wraper {
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    padding: 5px;
    width: 100%;
}

.hspInfoSubhead {
    font-size: 23px;
    font-weight: 600;
}

.hspinfo-contentdiv {
    height: auto;
    float: left;
    margin-top: 10px;
    width: 50%;
}

.hspInfofieldinputWidth {
    width: 100px !important;
}

.hspinfo-contentdiv label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 195px;
    padding-top: 5px;
    font-size: 14px;
}

input.hspInfofieldinput {
    padding: 1px;
    width: 260px;
    font-family: Helvetica, sans-serif;
    font-size: 1.2em;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 5px;
    text-indent: 5px;
    color: #8f8f8f;
    font-size: 13px;
    /* background: #ffffd8; */
}

input.hspInfofieldinputSmall {
    width: 95px;
}

.hspifoMantory {
    margin-left: 3px;
    display: inline-block;
    color: red;
}

input.hspInfofieldinput::placeholder {
    font-size: 13px;
}

select.hspInfofieldinput {
    padding: 1px;
    width: 260px;
    font-family: Helvetica, sans-serif;
    font-size: 13px;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 5px;
    text-indent: 5px;
    color: #8f8f8f;
    /* background: #ffffd8; */
}

.hspInfofieldinputDiv {
    padding: 1px;
    font-family: Helvetica, sans-serif;
    font-size: 13px;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 5px;
    text-indent: 5px;
    color: #8f8f8f;
    /* background: #ffffd8; */
}

.hspworkdetails-wrapper {
    background-color: rgb(242 242 242);
    /* margin-top: 10px; */
    margin-bottom: 10px;
    display: inline-block;
    padding: 5px;
    width: 100%;
}

.hspwrkdetal-ofcehour {
    background-color: white;
    padding: 1%;
    height: 100%;
    min-height: 395px;
    width: 61%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    font-size: 14px;
}

.officehrsHead {
    text-align: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    clear: both;
    background-color: #f2f2f2;
    padding: 8px;
    font-size: 20px;
    font-weight: 600;
}

.offcehrDaydiv {
    padding: 1%;
    height: auto;
    display: inline-flex;
}

.offcehrDaydiv label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 164px;
    padding-top: 5px;
    font-size: 14px;
}

.offcehrDay {
    float: left!important;
    margin-right: 1%!important;
}

.offcehrDay select {
    padding: 1px;
    width: 135px;
    font-family: Helvetica, sans-serif;
    font-size: 1em;
    margin: 0 5px 3px;
    border: 1px solid lightgray;
    height: 36px;
    color: #8f8f8f;
}

.offcehrDayFieldDiv {
    height: auto;
    float: left;
    width: 48%;
}

.offcehrDayFieldDiv label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 199px;
    padding-top: 5px;
    font-size: 14px;
}

.offcehrDayFieldDiv select {
    padding: 1px;
    width: 260px;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 5px;
    text-indent: 5px;
    color: #8f8f8f;
}

.hspInfoSubhead span {
    color: red;
    font-size: 23px;
}

.hspspecializationDiv {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    /* height: 250px; */
    height: 150px;
    border: 1px solid gainsboro;
    margin: 10px 0 0 0;
    padding: 10px 0 0 10px;
}

.table-bordered-hspSpecl {
    /* border: 1px solid #ddd; */
    border-collapse: separate;
    border-collapse: collapse;
    border-left: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 0
}

.table-bordered-hspSpecl th,
.table-bordered-hspSpecl td {
    padding: 8px;
    line-height: 20px;
    text-align: left;
    vertical-align: top;
    /* border-top: 1px solid #ddd; */
    word-wrap: break-word;
    width: 80px;
    /* border-left: 1px solid #ddd; */
}

.tdtxtLabel-hsp {
    display: inline;
    margin-left: 6px;
    font-size: 14px;
}

.immunisationTd {
    width: 20% !important;
}

.superadminDivBox {
    height: auto;
    /* width: 440px; */
    float: left;
    /* width: 48%; */
}

.superadminDivBox label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 195px;
    padding-top: 5px;
    font-size: 14px;
    clear: both;
    overflow: hidden;
    padding-top: 10px;
}

.superadminDivBox select {
    padding: 1px;
    width: 100px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    margin: 0 0 5px;
    border: 1px solid lightgray;
    height: 36px;
    color: #8f8f8f;
}

.superadminDivBoxcover {
    clear: both;
    overflow: hidden;
    padding-top: 10px;
}

.hsptaltimeHead {
    background-color: #f2f2f2;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-table;
    padding: 5px;
    width: 100%;
}

.hsptimefieldDiv {
    float: left;
    margin-right: 1%
}

.hsptimefieldDiv label {
    float: left;
    text-align: right;
    margin-right: 15px;
    padding-top: 5px;
    font-size: 14px;
    color: #393939;
}

.hsptimefieldDiv select {
    padding: 1px;
    width: 100px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    margin: 0 0 5px;
    border: 1px solid lightgray;
    height: 36px;
    color: #8f8f8f;
}

.addimgeDivHsp {
    width: 32px;
    height: 32px;
    background-position: center;
    text-align: center;
    float: right;
    margin-bottom: 5px;
    margin-top: 5px;
}

.addimgeDivHsp img {
    cursor: pointer;
}

.serviceselectHsp {
    padding: 1px !important;
    width: 260px !important;
    font-size: 14px !important;
    border: 1px solid #d3d3d3 !important;
    height: 36px !important;
    margin: 0 0 5px !important;
    text-indent: 5px !important;
    color: #8f8f8f !important;
}

.hsptaltimeHead .hsptimefieldDiv {
    margin-top: 5px;
}

.textarea-hspservice {
    padding: 1px;
    font-size: 14px;
    margin: 0 0 5px;
    border: 1px solid lightgray;
    text-indent: 5px;
    color: #8f8f8f;
    min-height: 80px;
    max-height: 90px;
    width: 100%;
}

.label-hspservice {
    margin-right: 15px;
    width: 195px;
    padding-top: 5px;
    font-size: 15px;
}

.textarea-hspservicewidth {
    max-height: 230px;
}

.dashboardhspOuter {
    position: relative;
}

.backArowdImgdiv {
    position: absolute;
    top: -42px;
}

.backArowdImgdiv a {
    padding: 5px 0px;
    border-radius: 8px;
    color: #8400ff;
    font-size: 14px;
    font-weight: 600;
}

.backArowdImgdiv span {
    font-size: 16px;
    color: #8400ff;
}

.AddNewHspbtnDiv {
    width: 30%;
    display: inline-block;
    vertical-align: top;
}

.pagination-HspList {
    text-align: right;
    width: 70%;
    display: inline-block;
}

.pagination-HspList ul li a {
    border-radius: 13%;
    height: 34px;
    min-width: 34px;
    width: auto;
    font-size: 13px;
    font-weight: 900;
}

.pagination-HspList ul li a:hover {
    background-color: var(--theme-MainColor);
    color: #fff !important;
}

.pagination-HspList ul li a:focus {
    background-color: var(--theme-MainColor) !important;
    color: #fff !important;
}

.pagination-HspList ul li a:active {
    background-color: var(--theme-MainColor) !important;
    color: #fff !important;
}

.pagination-HspList ul .active a {
    background-color: var(--theme-MainColor) !important;
    color: #fff !important;
}

.AddNewHspbtnDiv a {
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    color: #000 !important;
}

.AddNewHspbtnDiv a:hover {
    text-decoration: none;
    color: #000;
}

.pagination-HspListwidth {
    width: 100%;
}

.hspinfoimgaeuploadDiv {
    width: 264px;
    float: left;
}

.hspinfo-btnupload {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background-color: var(--theme-MainColor);
    border-color: var(--theme-MainColor);
    color: #FFF;
    border-radius: 1px;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42857;
    padding: 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    margin-right: 1%;
    width: 80px;
}
.hspinfo-btnupload-w{
    width: auto;
}

.hspinfo-btnupload input.hspinfoInputfieldupload {
    cursor: pointer;
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.seosectinTextHspMangemnt {
    color: red;
    font-size: 15px;
}

.generatebtn-seohsp {
    background-color: var(--theme-MainColor);
    border: 0;
    padding: 8px;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    width: 120px;
    font-size: 14px;
    font-weight: 600;
}

.generatebtn-seohsp:focus {
    outline: unset;
}

.generatebtnDiv-seohsp {
    margin-top: 20px;
}

.seofieldDivhsp label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 160px;
    padding-top: 5px;
    font-size: 14px;
}

.seofieldDivhsp-textarea {
    padding: 1px;
    width: 720px;
    max-width: 720px;
    min-width: 720px;
    /* font-family: Helvetica, sans-serif; */
    font-size: 14px;
    margin: 0 0 5px;
    border: 1px solid lightgray;
    text-indent: 5px;
    color: #8f8f8f;
    min-height: 80px;
    max-height: 90px;
    max-height: 90px;
}

.addHspPage-Wrapper {
    margin: 0 4px;
}

.addHspPage-Wrapper .react-tabs__tab--selected {
    border-color: var(--theme-MainColor);
    color: #fff;
    border-radius: 5px 5px 0 0;
    background-color: var(--theme-MainColor) !important;
}

.addHspPage-Wrapper .react-tabs__tab {
    padding: 5px 17px;
    background-color: #adadad38;
    border-radius: 5px 5px 0 0;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 600;
}
.addHspPage-Wrapper .react-tabs .reactTab-ul {
    width:100%;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.hspManagement-GoogleMap iframe {
    width: 100%;
    height: 300px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.HspManagemt-btns {
    padding-top: 75px;
}

.linkmobhspsubDiv {
    clear: both;
    padding-top: 20px;
}

.linkhspGenerateBtn-div {
    text-align: center;
    ;
}

.linkhspGenerateBtn {
    border: 0;
    padding: 8px;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    width: 256px;
    background-color: var(--theme-MainColor);
    margin-top: 30px;
    font-size: 15px;
    font-weight: 600;
}

.linkhspGenerateBtn:focus {
    outline: none;
}

.linkhspgenerteInput {
    width: 100%;
    padding: 1px;
    font-size: 1.2em;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 5px;
    text-indent: 5px;
    color: #8f8f8f;
    margin-top: 20px;
}

.linkgeneratedivOuter {
    background-color: #f4f4f4;
    padding: 30px;
    margin-top: 20px;
}


/* Add Images css begins here */

.ph-add-images .btn_upload {
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
    color: #fff;
    background-color: var(--theme-MainColor);
    border: 1px solid var(--theme-MainColor);
    padding: 5px 12px;
    height: 30px;
    margin-bottom: 10px;
    vertical-align: top;
    position: relative;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}

.imgUploadInline-hspaddimg {
    display: inline-block;
    margin: 0px 15px;
}

.imgUploadInline-hspaddimg img {
    width: 60px;
    height: 45px;
    object-fit: cover;
    object-position: center;
}

.ph-add-images .btn_upload:hover,
.ph-add-images .btn_upload:focus {
    background-color: var(--common-button-bgcolor);
}

.ph-add-images .yes {
    /* display: flex; */
    /* align-items: flex-start; */
    margin-top: 10px !important;
    /* padding-left: 18px; */
    display: inline-block;
    width: 300px;
    vertical-align: top;
    position: relative;
}

.imgCloseImg-hspadd {
    position: absolute;
    top: -6px;
    width: 22px;
}

.ph-add-images .btn_upload input {
    cursor: pointer;
    height: 100%;
    position: absolute;
    filter: alpha(opacity=1);
    -moz-opacity: 0;
    opacity: 0;
}

.ph-add-images .preview1 {
    visibility: hidden!important;
}

.ph-add-images .it {
    height: 50px;
    width: 50px;
    object-fit: cover;
    object-position: center;
    margin-left: 10px;
    visibility: visible!important;
    border: 1px solid #00000030;
    padding: 5px;
}

.ph-add-images .btn-rmv1,
.ph-add-images .btn-rmv2,
.ph-add-images .btn-rmv3,
.ph-add-images .btn-rmv4,
.ph-add-images .btn-rmv5 {
    display: none;
}

.ph-add-images .rmv {
    cursor: pointer;
    color: #fff;
    border-radius: 14px;
    border: none;
    display: inline-block;
    background: rgb(255 1 1);
    margin: -10px -10px;
    width: 20px;
    height: 20px;
    font-size: 10px;
    position: absolute;
}

.ph-add-images .rmv:hover {
    background: rgba(255, 0, 0, 0.7);
}

.ph-add-images .form-group {
    width: 95%;
}

.ph-addimagewraper {
    /* background: #e0e0e0; */
    /* padding: 25px; */
    margin-left: 5px;
    margin-top: 15px;
}

.ph-uploadedimg {
    position: relative;
    display: inline-block;
    width: 22%;
    margin: 4px;
}

.ph-uploadedimg img {
    width: 80%;
    height: 80%;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}

.ph-imglist {
    padding: 30px 0px;
    padding-left: 18px;
}

.ph-imglist .smssetng-svebtn {
    /* background-color: var(--theme-MainColor); */
    /* margin: 30px -15px; */
    margin-right: 8px;
}

.ph-addimagewraper .smssetng-btn {
    text-align: right;
}

.addImagebtn-hspManagement {
    background-color: var(--theme-MainColor);
    font-size: 14px;
    padding: 9px;
    text-decoration: none;
    width: 125px;
    display: inline-table;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin: 15px;
    border: 0;
    font-weight: 900;
}

.addImagebtn-hspManagement:focus {
    outline: unset;
}

.rmvbtn-imgeuplodHsp {
    cursor: pointer;
    color: #fff;
    border-radius: 14px;
    border: none;
    display: inline-block;
    background: rgb(255 1 1);
    margin: -10px -10px;
    width: 24px;
    height: 24px;
    font-size: 10px;
    position: absolute;
    right: 10px;
    top: 0px;
}

.hidden-menuImgUplod {
    background-color: #f3f3f3;
    padding: 30px;
    height: 249px;
    overflow-y: scroll;
    min-width: 400px;
    /* width: auto; */
    display: inline-block;
}

.addimgprevwImgdiv {
    display: inline-block;
    width: 32%;
    vertical-align: top;
}

.gendrfldHspdctr {
    font-size: 14px;
}

.doctorhspwrkdetal-ofcehour {
    background-color: white;
    padding: 1%;
    height: 100%;
    min-height: 395px;
    width: 70%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    font-size: 14px;
}

.doctroffcehrDay select {
    padding: 1px;
    width: 100px;
    font-family: Helvetica, sans-serif;
    font-size: 1em;
    margin: 0 5px 3px;
    border: 1px solid lightgray;
    height: 36px;
    color: #8f8f8f;
}

.sheduleLabeldoctoffce {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 164px;
    padding-top: 5px;
    font-size: 14px;
}

.sheduledivdoctr {
    display: inline-block;
    width: 30%;
    text-align: center;
}

.hspinfodoctor-contentdiv {
    height: auto;
    float: left;
    margin-top: 10px;
    width: 100%;
}

.hspinfodoctor-contentdiv label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 195px;
    padding-top: 5px;
    font-size: 14px;
}

.educationdoctr-contentdiv label {
    float: left;
    text-align: left;
    margin-right: 5px;
    width: 122px;
    padding-top: 5px;
    font-size: 15px;
}

.educationdoctr-contentdiv {
    height: auto;
    float: left;
    margin-top: 10px;
    width: auto;
    margin-right: 20px;
}

.priceinputproduredetl {
    width: 70px !important;
}

.priceinrtext {
    color: red;
    font-size: 14px;
}

.doctrcheckboxDivhsp {
    width: 50%;
    display: inline-block;
    clear: both;
}

.offcialinfowrapperhsp-bg {
    background-color: #f2f2f2;
    padding: 4px 5px 40px;
    float: left;
}

.procedhsp-contentdiv label {
    float: left;
    text-align: right;
    margin-right: 15px;
    padding-top: 5px;
    font-size: 14px;
}

.procedhsp-contentdiv {
    height: auto;
    float: left;
    margin: 5px;
    width: 48%
}

.procedhsp-contentdivWidth {
    width: auto !important;
}

.addimgfield-div label {
    font-size: 15px;
}

.addimgfield-div input {
    font-size: 15px;
}

.profield-div label {
    float: left;
    text-align: left;
    /* margin-right: 5px; */
    width: 114px;
    padding-top: 5px;
    font-size: 15px;
}

.profield-div {
    height: auto;
    float: left;
    margin-top: 10px;
    width: auto;
    margin-right: 20px;
}


/* Dr Management css begins here */

.ph_drmngmnt button {
    background-color: #adadad38;
    font-size: 14px;
    padding: 9px 25px;
    text-decoration: none;
    display: inline-table;
    text-align: center;
    color: #000;
    cursor: pointer;
    margin-right: 10px;
    border: 0;
    font-weight: 600;
    border-radius: 5px 5px 0 0;
}

.ph_drmngmnt button.active,
.ph_drmngmnt button:hover {
    background-color: var(--theme-MainColor);
    font-size: 14px;
    padding: 9px 25px;
    text-decoration: none;
    display: inline-table;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
    border: 0;
    /* font-weight: 900; */
}

.ph_drmngmnt button:focus {
    outline: none;
}

.ph_action_horizontal a {
    padding: 5px;
}

.ph_drmngmnt {
    border-bottom: 1px solid #ddd;
}

.ph_action_horizontal a.listLink-hspmangmnt {
    line-height: 20px;
}

.ph_actn_img {
    margin-bottom: 8px;
}

.sectionItemDivBorder {
    margin: 50px 0;
    text-align: center;
    position: relative;
    min-height: 400px;
}


/* Site setings edit */

.input-design,
.react-datepicker__input-container input {
    border: 1px solid #dcdcdc;
    background-color: #fff;
    width: 100%;
    padding: 0.25rem 1rem;
    resize: none;
    border-radius: 20px;
    color: #989898;
    margin-bottom: 15px;
}

.popup-overlay {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99;
    /* display: none; */
}

.popup-contentDiv {
    padding: 10px;
    border-radius: 10px;
    position: relative;
    background: rgb(255, 255, 255);
    width: 50%;
    margin: auto;
    border: 1px solid rgb(187, 187, 187);
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.modal-popup,
.modal-popup p {
    font-size: 14px;
}

.modal-popup>.close-popup {
    cursor: pointer;
    position: absolute;
    display: block;
    line-height: 0;
    right: 5px;
    top: 5px;
    font-size: 24px;
    background: #3f3d56;
    border-radius: 18px;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    opacity: 0.8;
    transition: 1s;
    padding: 12px 0;
}

.modal-popup>.header {
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 5px;
    color: #3f3d56;
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    height: auto;
}

.modal-popup>.content {
    width: 100%;
    padding: 10px 20px;
    color: #0f0f10;
    max-height: 85vh;
    overflow: auto;
}

.row-margin {
    margin-bottom: 15px;
}

.divBtn {
    color: var(--common-button-bgcolor);
    padding: 5px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid var(--common-button-bgcolor);
    margin: 10px 10px;
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    min-width: 140px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}

.text-center .cancelButtonBg,
.text-center .cancelButtonBg:hover {
    background-color: var(--cancel-button);
    border-color: var(--cancel-color);
    color: #fff;
}

.formButtonBg {
    background-color: var(--common-button-bgcolor);
    color: var(--common-button-txtcolor);
    padding: 7px 20px;
    border-radius: 20px;
    font-weight: 600;
    border: 0;
    font-size: 14px;
    line-height: unset;
}

.modal-popup>.close-popup:hover {
    opacity: 1;
    color: #fff;
    text-decoration: none;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    /* width: 100%; */
    width: 260px;
}

.input-design:focus,
.react-datepicker__input-container input:focus {
    outline: none;
    box-shadow: none;
    border:none;
}

.modal-popup>.content {
    overflow: inherit;
}

.react-datepicker__input-container .doctrHsp-dateinput {
    padding: 1px;
    width: 260px;
    font-family: Helvetica, sans-serif;
    font-size: 1.2em;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 5px;
    text-indent: 5px;
    color: #8f8f8f;
    font-size: 13px;
    /* background: #ffffd8; */
    border-radius: unset !important;
}

.admindctr-specialzation {
    display: inline-block;
    width: 265px !important;
    border-top: none !important;
}

.admindctr-specialzation-tr {
    /* border-bottom: 1px solid #dee2e6; */
    display: inline-block;
}

.admindctr-specialzation-tr:last-child {
    border-bottom: none;
}

.table-specializtin-admin {
    width: auto;
}

input:focus,
select:focus,
textarea:focus {
    outline: none;
}

.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    top: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.85);
    left: 0;
    right: 0;
}

.loader:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    width: 100%;
    height: 100%;
    background: url("../image/theme/loader.gif") 50% 50% no-repeat ;
}

.footer {
    clear: both;
}

.cursorpointer {
    cursor: pointer;
}

.addrepwrapperborder {
    margin: 40px 0;
    text-align: center;
    position: relative;
    min-height: 400px;
    border: 1px solid #ddd;
    border-radius: 7px;
}

.addrepOuter {
    height: auto;
    float: left;
    margin-top: 50px;
    width: 60%;
    margin-bottom: 30px;
}

.addrep-contentdiv {
    height: auto;
    float: left
}

.addrep-contentdiv label {
    float: left;
    text-align: right;
    margin-right: 20px;
    width: 250px;
    padding-top: 5px;
    font-size: 14px;
}

.addrepfieldinput {
    padding: 1px;
    width: 260px;
    font-family: Helvetica, sans-serif;
    font-size: 1.2em;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 10px;
    text-indent: 5px;
    color: #8f8f8f;
    font-size: 13px;
    /* background: #ffffd8; */
}

.addrep-btn {
    clear: both;
    text-align: right;
    margin-right: 50px;
    margin-bottom: 15px;
}

.bannerthumbImg img {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    object-position: center;
}

.bannerthumbImg img:hover {
    -webkit-transform: scale(4);
    -moz-transform: scale(4);
    -o-transform: scale(4);
    -ms-transform: scale(4);
    transform: scale(4);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}

.table-baner-res {
    overflow-y: hidden;
}

.banerdetlwrapperborder {
    text-align: center;
    position: relative;
    min-height: 400px;
    border: 1px solid #ddd;
    border-radius: 3px;
}

.hspbannerdetlPage-Wrapper .react-tabs__tab-list {
    border-bottom: none;
    margin: 0 0 0px;
    padding: 0;
}

.hspbannerdetlPage-Wrapper {
    margin: 35px 4px;
}

.hspbannerdetlPage-Wrapper .react-tabs__tab-list .react-tabs__tab--selected {
    background: #8200ff;
    border-color: #8200ff;
    color: #fff;
    border-radius: 5px 5px 0 0;
    font-size: 15px;
    font-weight: 600;
}

.hspbannerdetlPage-Wrapper .react-tabs__tab {
    padding: 5px 17px;
    background-color: #adadad38;
    border-radius: 5px 5px 0 0;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 600;
}

.bannerdetlhead-hsp h2 {
    font-size: 20px;
    color: #000;
    text-align: left;
    font-weight: 600;
    margin-bottom: 35px;
}

.addbanerhspOuterDiv {
    margin: 30px 50px;
}


/* .bannerdetlhead-hsp{
    border-bottom: 2px solid #8200ff;
} */

.addbannerhsp-contentdiv {
    height: auto;
    float: left;
    width: 46%;
    margin-bottom: 7px;
}

.addbannerhsp-contentdiv label {
    float: left;
    text-align: right;
    margin-right: 0px;
    width: 150px;
    padding-top: 5px;
    font-size: 14px;
}

.addbanerhspfieldinput {
    padding: 1px;
    width: 260px;
    font-family: Helvetica, sans-serif;
    font-size: 1.2em;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 10px;
    text-indent: 5px;
    color: #8f8f8f;
    font-size: 13px;
}

.addbanr-hspimgaeuploadDiv {
    /* width: 152px; */
    float: left;
    width: 138px;
}

.hspaddbanr-btnupload {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background-color: var(--theme-MainColor);
    border-color: var(--theme-MainColor);
    color: #FFF;
    border-radius: 1px;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42857;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* margin-right: 13%; */
    width: 83px;
}

.hspaddbanr-btnupload input {
    cursor: pointer;
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.addbannerhsp-contentdiv-clear {
    clear: both;
}

.addbannerhsp-contentdiv-check {
    width: 43% !important;
}

.replogoImg img {
    width: 35px;
    height: 35px;
    object-position: center;
    object-fit: cover;
}

.repdescritn-pharma {
    text-overflow: ellipsis;
    width: 10vw;
    overflow: hidden;
    white-space: nowrap;
}

.replogoImg img:hover {
    -webkit-transform: scale(4);
    -moz-transform: scale(4);
    -o-transform: scale(4);
    -ms-transform: scale(4);
    transform: scale(4);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    z-index: 999;
    position: relative;
}

.addrepimgdivmob {
    width: 165px;
}

.fileUpload-imgreptop {
    margin-top: 0;
}

.fileUpload-imgreptop img {
    height: 45px;
    object-fit: cover;
    object-position: center;
}

.uplod-imge-inlinebaner {
    display: inline-block;
}

.addbannerhsp-contentdiv-upload {
    width: 32%;
    text-align: right;
}

.siteset-sectnDivBorder {
    margin: 25px 0;
    text-align: center;
    position: relative;
    min-height: 400px;
}

.sitegrphsp-input input {
    padding: 1px;
    width: 260px;
    font-family: Helvetica, sans-serif;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 5px;
    text-indent: 5px;
    color: #8f8f8f;
    font-size: 13px;
    border-radius: unset !important;
}

.siteset-textareafield {
    width: 100%;
}

.label-sitesethsptextarea {
    margin-right: 15px;
    width: 100%;
    padding-top: 5px;
    font-size: 15px;
}

.sitesetng-svebtn {
    background-color: var(--theme-MainColor);
    font-size: 14px;
    padding: 9px 25px;
    text-decoration: none;
    /* width: 80px; */
    display: inline-table;
    text-align: center;
    color: #fff !important;
    cursor: pointer;
    margin: 5px;
    border: 0;
    font-weight: 600;
}

.sitesetng-cancelbtn {
    background-color: #adadad;
    font-size: 14px;
    padding: 10px 20px;
    text-decoration: none;
    border: 0;
    color: #fff !important;
    cursor: pointer;
    margin: 5px;
    /* width: 98px; */
    font-weight: 600;
}

.sitesetng-svebtn:hover {
    color: #fff;
    text-decoration: none;
}

.sitesetngbtndiv {
    width: 98%;
}

.site-promodateinput input {
    padding: 1px;
    width: 233px;
    font-family: Helvetica, sans-serif;
    font-size: 1.2em;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 4px 5px;
    text-indent: 5px;
    color: #8f8f8f;
    float: left;
    font-size: 13px;
    border-radius: unset;
}

.special-popuptextareawidth {
    width: 95%;
}

.hspInfoSubhead-site {
    font-size: 20px;
    font-weight: 600;
}

.addNew-btn-journel:focus {
    outline: unset;
}

.pos-rel {
    position: relative;
}

.dctrpanel-headinput input , .react-datepicker__input-container input{
    border-radius: unset;
    /* border: 1px solid lightgray; */
    font-size: 14px;
}
.borderRad0{
  border-radius: 0px !important;
}
.gap3{
  gap:6px;
}
.table-headerDctrPanel {
    padding: 7px;
    margin-left: 2px;
    margin-top: 15px;
    height: 48px;
}

.conf-cursorpointer {
    cursor: pointer;
    width: 25px;
    height: 25px;
    margin-top: 3px;
}
.cursor-pointer{
  cursor: pointer;
}

.hspinputborderrad {
    border-radius: unset !important;
    padding: 0 !important;
}

.hosptable-tdinline {
    width: 425px !important;
    display: inline-block
}

.hosptable-tdinline {
    border-top: none !important;
}

.tmelabel-hspadmin {
    width: 95px;
    text-align: left !important;
}

.tmelabel-hspadmin-to {
    width: 80px;
    text-align: left !important;
}

.servcelabel-hspwidth {
    width: 100px !important;
}

.hspworkdel-widthselect {
    height: 86px !important;
}

.attachment-cancelImg {
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 35px;
    top: -12px;
}

.attachment-downloadImg {
    padding: 1px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0px;
    bottom: 0;
}

.attchment-adddiv {
    display: inline-block;
    width: 14%;
    margin: 4px;
    position: relative;
}

.label-smssetngbold {
    width: 190px !important;
    text-align: left !important;
    display: inline-block;
    font-weight: 800;
}

.label-smssetnginline {
    display: inline-block
}

.dctrofce-inputhspid {
    width: 80px !important;
    margin-left: 10px !important;
}

.adrsmap-hspadmin {
    position: absolute;
    width: 28% !important;
    height: 135px !important;
    /* top: 0; */
    right: 15%;
    /* z-index: 9999; */
    /* margin-top: 14px; */
    display: inline-block;
}

.adrsmap-hspinput {
    z-index: 9999;
    position: absolute;
    width: 40%;
    display: inline-block;
}

.lanlongdiv {
    position: absolute;
    margin-top: 45px;
    margin-bottom: 20px;
    /* z-index: 11; */
}


/* .adrsmap-hspinput-widthauto .autocomplete-dropdown-container{
    z-index: 99999;

} */

.hspinfo-contentdiv-langlog {
    width: 100%;
}

.mapinput-marginhsp {
    margin-left: 6px !important;
}


/* .canvas{
    height: 100vh;
    background-image: url(https://images.pexels.com/photos/802412/pexels-photo-802412.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
    background-size: cover;
    background-position: center;
  } */

.location-search-input {
    font-family: 'Lato', sans-serif;
    width: 500px;
    height: 40px;
    background: none;
    border: 2px solid black;
    /* border-radius: 5px; */
    /* font-size: 1rem; */
    color: black;
    /* font-weight: bold; */
    /* margin-top: 300px; */
    margin-left: 5rem;
}

.input-suggestion {
    font-family: 'Lato', sans-serif;
    width: 500px;
    height: 60px;
    background-color: white;
    border-bottom: 1px black dotted;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: black;
    margin-left: 5rem;
}

.canvas {
    position: absolute;
    z-index: 9999;
}

.hspworkdetails-wrappermargin {
    margin-top: 10%;
}

.cursor-pointer {
    cursor: pointer;
}

.react-date-dctr-width .react-datepicker-wrapper {
    width: 50% !important;
    border-radius: unset !important;
}

.react-date-dctr-width .react-datepicker-wrapper input {
    border-radius: unset !important;
}

.react-date-dctr-widthiput .react-datepicker__input-container input {
    width: 100% !important;
}

.association-input-doctr .css-1s2u09g-control {
    width: 260px !important;
    border: 1px solid #d3d3d3 !important;
    box-shadow: unset !important;
    border-radius: unset !important;
}

.association-input-doctr .css-b62m3t-container .css-1pahdxg-control {
    border-color: #d3d3d3 !important;
    width: 260px !important;
    box-shadow: unset !important;
}


/* .association-input-doctr .css-b62m3t-container .css-1pahdxg-control:hover, .association-input-doctr  .css-1pahdxg-control:focus{
    border-color: #d3d3d3 !important;
    width: 260px !important;
    height: 35px !important;
    min-height: auto !important;
    box-shadow: unset !important;
} */

.association-input-hspMangmnt .css-6lx3xi-control {
    width: 260px !important;
    height: 35px !important;
    min-height: auto !important;
    border: 1px solid #d3d3d3 !important;
    box-shadow: unset !important;
    border-radius: unset !important;
}

.association-input-hspMangmnt .css-b62m3t-container .css-1sykjzh-control {
    border-color: #d3d3d3 !important;
    width: 260px !important;
    height: 35px !important;
    min-height: auto !important;
    box-shadow: unset !important;
}


/* .association-input-hspMangmnt .css-b62m3t-container .css-1sykjzh-control:hover, .association-input-hspMangmnt  .css-1sykjzh-control:focus{
  border-color: #d3d3d3 !important;
  width: 260px !important;
  height: 35px !important;
  min-height: auto !important;
  box-shadow: unset !important;
} */

.search-dctrdiv-clear {
    clear: both;
}

.doctr-wraper-border {
    border: 1px solid #ddd;
    padding: 30px;
    border-top: none;
    border-radius: 5px;
}

.doctr-wraper-border-addimge {
    border: 1px solid #ddd;
    padding: 30px;
    border-radius: 5px;
}

.adrsmap-hspinput-widthauto {
    width: auto !important;
}

.superadminDivBox-width-reg {
    width: 50%;
}

.addHspPage-Wrapper .react-tabs__tab-list {
    margin: 0;
}

.healthInput-radius-date {
    border-radius: unset !important;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #ddd !important;
}

.popupinput-widthproduct {
    width: 430px;
}

.healthinfo-canclbtn {
    padding: 12px 23px !important;
    color: #fff !important;
}

.tag-fieldhealthinfo {
    border-radius: unset !important;
}

.img-upld-healthinfo {
    background-color: var(--theme-MainColor) !important;
    color: #fff !important;
    border-radius: unset !important;
    height: 36px !important;
}

.btn-messaging-dctr {
    padding: 5px;
    color: #fff !important;
    font-size: 13px;
    background-color: var(--theme-MainColor);
    border: 1px solid var(--theme-MainColor);
}

.dctr-msg-field {
    width: 40%;
    margin: 3px;
}

.dctr-msg-inputfield {
    width: 55%;
}

.dctr-msg-name {
    color: var(--theme-MainColor) !important;
    font-size: 13px;
    font-weight: 800;
}

.sectionTag-admindash-width {
    width: 235px;
}

.journl-des-txt p {
    font-size: 14px;
}

.hspinfo-contentdiv-langlog .autocomplete-dropdown-container {
    position: absolute;
    z-index: 99999;
}

.hspinfo-contentdiv-langlog .input-suggestion {
    font-size: 14px;
    padding: 25px 20px;
    /* height: 36px; */
    font-weight: unset;
    border: 1px solid #e0e0e0;
    left: 0;
}

.table-bordered-hsp .textCenter {
    text-align: center;
}

.doctroffcehrDayRow {
    display: flex;
    margin-bottom: 10px;
}

.doctroffcehrDayRow select {
    width: 70px;
}

.table_inside .tablehead-hspmng {
    padding: 0 10px;
}

.table.table-bordered-Head {
    display: flex;
    font-weight: 700;
    background-color: #f2f2f2;
    vertical-align: middle;
    font-size: 14px;
    border-bottom: 2px solid #dee2e6;
    align-items: center;
}

.table.table-bordered-row {
    display: flex;
}


/* .hspworkdetails-wrapper-margintop
{
    margin-top:150px;
} */

.gmap-div {
    display: flex;
}

.health-info-table-list td,
.health-info-table-list th {
    border-right: 1px solid #ccc;
}

.action_td {
    width: 18px;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.action_td img {
    max-width: 100%;
}

.action_td a {
    display: contents;
}

.min_width {
    min-width: 130px;
}

.min_width .td_img {
    width: 40px;
}

.td_img img {
    width: 95px;
    height: 95px;
}

.td_img {
    min-height: 100px;
}

.td_img img:hover {
    width: 20%;
    height: 14%;
    position: absolute;
    transition: .5s;
}

.input_icon {
    width: 25px;
}

.input_icon img {
    max-width: 100%;
    margin-left: 10px;
    cursor: pointer;
}

.file_img {
    width: 80px;
}

.file_img img {
    max-width: 100%;
}

.purple_edit .react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.purple_edit td {
    font-size: 12px;
}

.bg_row {
    background-color: #e0e0e0;
    border: 1px solid #dcdcdc;
}

.action_tdtr a {
    width: 22px;
    display: inline-block;
}

.action_tdtr img {
    max-width: 100%;
}

.link_overflow {
    width: 200px;
    overflow: auto;
}

.rdw-editor-main {
    height: 200px !important;
}
.smssetng-svebtn-padd{
    padding: 7px 25px;
}
.addHspPage-Wrapper .react-tabs .reactTab-ul::-webkit-scrollbar
{
    display: none;  /* Safari and Chrome */
}
.hosptalList {
    list-style: none;
    border: 1px solid #ededed;
    background-color: #fff;
    width: 100%;
    /* border-radius: 14px; */
    padding-left: 7px;
    padding: 8px 10px;
    cursor: pointer;
}
.hosptalList:hover{
    background-color: #e6e6e6;
}
.emoji-text, .count-text{
    font-size: 15px;
    font-weight: 600;
}
.close-popup-top{
    top: 3px;
    right: 7px;
}
.popup-content-padd{
    padding: 32px 15px;
}
.select-width-field{
    width: 250px;
}
.auto-complete-wrapper {
    padding: 0;
    background-color: #fff;
    width: 400px;
    position: relative;
    z-index: 1;
    box-shadow: 2px 2px 10px #c3c3c3;
    border-radius: 5px;
    overflow: hidden;
}
.custom_popupstyle .modal-dialog{
    max-width: 550px;
}   
.custom_popupstyle .header{
    text-align: left;
}
.custom_popupstyle .modal-header{
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
}
.custom_popupstyle{
    overflow-x: hidden;
    overflow-y: auto;
}

/* doctor registration start */
.drRegistrationForm .label-text {
    text-align: left;
    color: #606060;
    position: relative;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 13px;
}
.doctrlabelTop {
    top: 6px;
}
.drRegistrationForm {
    border: 1px solid gainsboro;
    padding: 20px 8%;
    border-radius: 20px;
    font-size: 13px;
}
.form-section .label-text-cln:after{
    position: absolute;
    content: ":";
    right: 0;
}
.star-red {
    color: #ff4040;
}
.selecttitlewidth {
    width: 32% !important;
}
.drRegistrationForm select.input-design, .drRegistrationForm .input-design {
    padding: 0.35rem;
}
.inputdesignSpace {
    width: 64% !important;
}
.drRegistrationForm .fileUpload-medical, .drRegistrationForm .fileUpload-medical:hover {
    padding: 4px 0px;
    border: none;
}
.fileUpload-medical, .fileUpload-medical:hover {
    position: relative;
    overflow: hidden;
    background: transparent;
    border: 1px solid var(--theme-MainColor);
    text-align: center;
    padding: 5px 10px;
    border-radius: 20px;
    color: var(--theme-MainColor);
    height: auto;
    width: auto;
    float: left;
    font-weight: 500;
    cursor: pointer;
    min-width: 140px;
}
.input-file-container {
    position: relative;
}
.input-file {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 225px; */
    opacity: 0;
    /* padding: 14px 0; */
    cursor: pointer;
    color: var(--theme-MainColor);
}
.input-file-trigger {
    display: block;
    padding: 5px 20px;
    border-radius: 20px;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
    color: var(--theme-MainColor);
    margin: 0;
    border: 1px solid var(--theme-MainColor);
}
.bg-border {
    border: 1px solid #dcdcdc;
    border-radius: 20px;
    width: 94%;
}
.pos-rel {
    position: relative;
}
.labelcolumnaftr::after {
    position: absolute;
    content: ":";
    right: 0;
}
.input-design-widthclinic {
    width: 70% !important;
    margin-right: 5px;
}
.drRegistrationForm .form-section-bgcolor {
    border-radius: 20px;
    padding: 15px 0 0;
    width: 94%;
}
.form-section-bgcolor {
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 20px 0;
}
.reg-actn {
    position: absolute;
    right: 5%;
    font-size: 20px;
}
.edu-icon-size .fa {
    font-size: 24px;
}
.reg-plus .fa {
    color: green;
}
.pluiconbutnborder {
    border: none;
    background-color: transparent;
}
.input-file-trigger:hover, .input-file-trigger:focus{
    color: var(--focus-input-color);
    border-color: var(--focus-input-color);
}

.loader_imgDiv-pos {
    position: relative;
}

.loader_imgDiv {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    align-items: center;
}

.loader_imgDiv img {
    margin: 0 auto;
}
.mainBx {
    width: 100%;
    padding: 20px;
    max-height: 300px;
    overflow-y: auto;
}
.subBx {
    width: 50%;
    display: inline-block;
    padding: 5px 10px;
}
.spanLeft {
    float: left;
    margin-right: 5px;
    width: 48%;
}
.spanRight {
    float: right;
    margin-right: 5px;
    width: 48%;
}
.tdtxt {
    margin-left: 5px;
}
.patientupload_popup{
    margin: 0 auto;
    text-align: center;
    padding: 20px;
}
.uploadtext-popup{
    font-size: 17px;
    text-align: center;
    padding-bottom: 20px;
    font-weight: 600;
}
.popup-successmsg{
    font-size: 22px;
    color: green;
    padding-top: 25px;
}

.input-design-journal li{
    cursor: pointer;
  }
.input-design-journal li:hover{
    background-color: #f5f5f5;
  }
/* doctor registration end */

@media print{
    .qrcode-container{
        max-width: 45% !important;
        margin:  0 auto;
    }
}
/******************* whatsapp *******************/
.whatsapp-main .whatsapp {
    position: fixed;
    content: "";
    right: 0;
    bottom: 55px;
    z-index: 999;
    /* background-color: #15940c;
    border-radius: 5px 0 0 5px;
    padding: 4px 10px 5px 5px; */
    cursor: pointer;
    transition: 1s;
  }
  /* .whatsapp-main .whatsapp:hover {
    background-color: #0e8b06;
    box-shadow: 2px 2px 10px #444;
  } */
  .whatsapp-main .whatsapp img {
    transition: 1s;
    margin-right: 5px;
    filter: drop-shadow(1px 1px 5px #000);
  }
  .whatsapp-main .whatsapp:hover img {
    transform: scale(1.1);
  }
  .whatsapp-main .whatsapp {
    color: #fff;
    text-decoration: none;
  }
  /******************* whatsapp *******************/

  .input-design-journal{
    border: 1px solid #DCDCDC;
    background-color: #fff;
    width: 100%;
    padding: 0 7px;
    resize: none;
    border-radius: 6px;
    color: #989898;
    margin-bottom: 15px;
    list-style: none;
    max-height: 200px;
    overflow: auto;
    position: absolute;
    left: 15px;
    top: 100%;
    z-index: 1;
  }
  .input-design-journal:focus{
    outline: unset;
  }
  .bg-grey {
    background-color: #F7F7F7;
  }
.poll-report-div{
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}
.poll-report-div:last-child{
  border-bottom: 0;
  padding-bottom: 0;
}
.poll-report-chart{
  width: 50%;
}
.poll-report-view{
  width: 50%;
  padding-left: 50px;
}
.poll-report-view-head{
  font-family: 'OpenSans-Semibold';
  font-size: 14px;
  position: relative;
  margin-bottom: 5px;
  display: flex;
}
.poll-report-body{
  max-height: 100px;
  overflow: auto;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 10px 15px;
  border-radius: 5px;
  scrollbar-width: thin;
  scrollbar-color: #555 #F1F1F1;
  background-color: #eee;
  margin-left: 15px;
}
.poll-body-text{
  font-size: 13px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-left: 15px;
}
.poll-report-body .poll-body-text{
  margin-left: 0;
}
.poll-body-text .float-right{
  max-width: 150px;
  flex: none;
}
.poll_close_div {
  position: absolute;
  top: -22px;
  right: -20px;
}
.poll_close_div01{
  position: absolute;
  right: -10px;
  top: -20px;
}
.span_medium{
  font-family: 'OpenSans-Semibold';
    margin-right: 5px;
}
.poll_history p{
  font-size: 17px;
}
.poll-report-ul{
  padding-left: 0;
  counter-reset: my-sec-counter;
  margin-top: 30px;
}
.poll-report-li{
  margin-bottom: 10px;
  list-style: none;
  margin-bottom: 40px;
}
.poll-report-head{
  background-color: #fff;
  padding: 10px 0px;
  border-radius: 5px;
  font-family: 'OpenSans-Semibold';
  font-size: 16px;
  display: flex;
  /* border: 1px solid rgb(0 0 0 / 10%); */
}
.poll-report-head-span{
  font-family: 'OpenSans-Regular';
  margin-left: 5px;
}
.poll-body-text:last-child{
    margin-bottom: 0;
}
.react-datepicker-popper{
  z-index: 4 !important; 
}
.invisible-label{
  visibility: hidden;
  display: block;
}
.search-btn-report{
  padding: 4px 5px;
    width: 100%;
}
.search-btn-div{
  margin-top: 2px;
}
.div-padding-right{
  padding-right: 0;
}
.alert-msgg {
  font-family: "OpenSans-Regular";
  width: 90%;
  margin: 15px auto 0;
}
.hospitallistUlclinic-width{
  overflow-y: auto;
  max-height: 300px;
}
.poll_popup{
  position: unset;
  margin: 0;
  transform: unset;
}
.poll_popupdiv{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgb(0 0 0 / 38%);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embedPopup .modal-popup>.close-popup{
  padding: 15px 0;
  top: 10px;
  right: 10px;
}
.no-data-div{
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  border-radius: 50px;
  text-align: center;
  font-size: 16px;
}

.doctor-report .header{
  border-left: 20px solid #7030a0;
  padding-left: 25px;
}
.doctor-report .header-top{
  /* border-bottom: 1px solid #7030a0; */
  /* padding:10px; */
}
.main-outer{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 70%;
  margin: 20px auto;
  font-family: 'Avenier Regular';
}
.header-h1{
  font-size: 23px;
  color:#7030a0;
}
.header-date{
  color:#7030a0;
  font-size: 16px;
  margin-bottom: 0px;
}
.utilization-snapshot{
  border-left: 20px solid #0070c0;
  padding-left: 25px;
  /* margin: 15px 0px; */
}
.utilization-head{
 color: #0070c0;
 font-size: 20px;
 padding-top:20px;
}
.count-report{
  color: #0070c0;
  font-size: 21px;
  padding-bottom: 8px;
}
.increase{
  color:#008f92;
  font-size: 15px;
}
.decrease{
  color:#be2533;
  font-size: 15px;
}
.patient-visit-count{
  font-size: 15.5px;
  margin-bottom: 5px;
}
.violet-border{
  border: 1px solid #7030a0;
    width: 90%;
    margin: 4px auto;
}
.stat-box{
  margin: 10px 0px;
}
.border-blue{
  border: 3px solid #0070c0;
    padding: 15px 0px;
    /* border-left: 0px; */
    border-radius: 10px;
    margin:0px;
}
.blue-border{
  border: 1px solid #0070c0;
  width: 90%;
  margin: 4px auto;
}
.business-snapshot{
  border-left: 20px solid #466148;
  padding-left: 25px;
  width:97%;
  padding-bottom: 15px;
}
.border-green{
  border: 3px solid #466148;
    padding: 7px 0px;
    /* border-left: 0px; */
    border-radius: 10px;
    margin:0px;
}
.business-head{
  font-size: 20px;
  color:#466148;
  padding-top: 20px;
}
.google-snapshot{
  border-left: 20px solid #f44d6a;
  padding-left: 25px;
  width:97%;
  padding-bottom: 15px;
}
.google-head{
  color:#f44d6a;
  font-size: 20px;
  padding-top: 20px;
}
.green-border{
  border: 1px solid #466148;
  width: 90%;
  margin: 4px auto;
}
.border-red{
  border: 3px solid #f44d6a;
  padding: 7px 0px;
  /* border-left: 0px; */
  border-radius: 10px;
  margin:0px;
}
.border-grey{
  border-bottom: 2px solid #ccc;
}
.red-border{
  border: 1px solid #f44d6a;
  width: 90%;
  margin: 4px auto;
}
.violet-text{
color:#7030a0;
}
.bottom-text{
font-size: 17px;
}
.line-chart-report{
  border: 3px solid #0070c0;
  /* border-left: 0px; */
  border-radius: 10px;
  margin-top: 10px;
  width:100%;
}
.graph-line{
  padding-right: 40px;
}
.business-profile-head{
    font-size: 18px;
    color: #000;
}
.text-small {
  font-size: 12px;
}
.border-rad0{
  border-radius: 0px !important;
}
.cursor-pointer{
  cursor: pointer;
}
.input-design-btn {
  color: #989898;
  font-size: 13px;
  text-align: left;
  width: 100%;
outline: unset;
}
.input-design-btn:focus {
  outline: unset;
  box-shadow: unset;
}
.google-my-business {
  position: relative;
}
.access-denied_section {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(62 62 62 / 10%);
  border-radius: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.access-denied_content {
  padding: 20px;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  border: 1px solid #f44d6a;
  background-color: #6b6b6b;
}

.analytics_box .card {
  border: 1px solid rgb(239 223 223 / 50%);
  border-radius: .428rem;
  margin-bottom: 5px;
  /* box-shadow: 0 0 5px 1px rgb(136 152 163 / 39%); */
  background: rgb(189 180 180 / 20%);
}
.analytics_blue {
  background-color: #8405ff;
}
.web-analytics-db{
  filter: invert(1);
}
.analytics_user {
  background: #8405ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 8px;
}
.analytics_box .card-body, .analytics_user {
  display: -ms-flexbox;
  display: flex
;
  -ms-flex-align: center;
  align-items: center;
}
.analytics_userdata {
  margin-left: 10px;
}
.userdata_head {
  font-weight: 700;
  font-size: 20px;
}
.userdata_content {
  font-weight: 600;
  font-size: 14px;
}