:root {
  --theme-subcolor: #0071e3;
  --common-color: #0071e3;
  --theme-color: #0f4fa1;
  --common-button-bgcolor: #0f4fa1;
  --common-button-txtcolor: #fff;
  --focus-input-color: #0071e3;
}
  * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: "Arial",Helvetica,Helvetica Neue,sans-serif;
    color: #616161;
    font-size: 13px;
  }
  .header-reliance {
    overflow: visible;
    width: 100%;
    position: relative;
    z-index: 1;
  }
  .logo-reliance {
    padding: 6px 0;
  }
  .logo-reliance a {
    outline: 0;
  }
  .logo-reliance img {
    width: 185px;
  }
  .menu-reliance {
    width: 100%;
    text-align: right;
    background-color: var(--theme-color);
    height: 35px;
  }
  .footerCopyRight {
    padding-left: 15px;
    font-size: 12px;
    background-color: #484848;
    text-align: center;
    color: #fff;
    padding: 20px 5px;
    letter-spacing: 0.5px;
}
.purpleWrap {
  position: relative;
  float: unset;
}
.purpleWrap .footerWrapper {
  bottom: -85px;
}
.menu-reliance ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.menu-reliance li {
  padding: 9px;
  display: inline-block;
  position: relative;
  font-size: 13px;
}
.menu-reliance li a, .menu-reliance li a:hover {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 1px 13px;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Arial",Helvetica,Helvetica Neue,sans-serif;
}
.main-wrapper1 {
  margin: 20px 0 60px;
  padding: 20px 0;
  min-height: 66vh;
}
.breadcrumb {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #dedede;
  padding: 10px 25px;
}
.breadcrumb a, .breadcrumb li {
  color: #4d4d4d;
  text-decoration: none;
}
.main-wrapper1 li{
  font-size: 14px;
}
.breadcrumb i.fa {
  margin: 0 8px;
  font-size: 10px;
  color: #4d4d4d;
}
.form-head {
  font-size: 16px;
  font-weight: 600;
  color: #4d4d4d;
  margin-bottom: 25px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
}
.custom-search-subWidth {
  max-width: 18%;
}
.custom-search-subWidth-sm {
  max-width: 16%;
}
.input-design,
.react-datepicker__input-container input {
    border: 1px solid #dcdcdc;
    background-color: #fff;
    width: 100%;
    padding: 0.25rem 1rem;
    resize: none;
    border-radius: 20px;
    color: #545454;
    margin-bottom: 15px;
}
.custom-search select,
.custom-search .input-design,
.custom-search .react-datepicker__input-container input {
    font-size: 12px;
    padding: 0.4rem 1rem;
}
.react-datepicker-wrapper {
  width: 100%;
  position: relative;
}

.formButton {
  color: var(--common-button-bgcolor);
  padding: 5px 25px;
  border-radius: 20px;
  text-transform: capitalize;
  border: 1px solid var(--common-button-bgcolor);
  margin: 10px 10px;
  background-color: #fff;
  text-decoration: none;
  font-size: 14px;
  min-width: 140px;
}

.formButton-grey {
  color: #000;
  padding: 5px 25px;
  border-radius: 20px;
  text-transform: capitalize;
  border: 1px solid #cccccc;
  margin: 10px 10px;
  background-color: #fff;
  text-decoration: none;
  font-size: 14px;
  min-width: 140px;
  font-weight: 500;
}
.formButtonsearch-width {
  min-width: 100px;
}
.formButtonsearch-width:hover {
  text-decoration: none;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover, .pagination a:hover {
  color: #fff;
  background-color: #3f3d56;
  cursor: pointer;
}
.admin-dashBtns {
  width: auto;
}

@media (max-width: 992px)
{
  .graph-outer{
    margin-top: 20px;
    }
  .custom-search-subWidth, .custom-search-subWidth-sm {
      max-width: 24%;
  }
  .breadcrumb li {
    font-size: 12px;
  }
  .breadcrumb i.fa {
    margin: 0 5px;
    font-size: 8px;
  }
}
@media (min-width: 992px)
{
  .custom-search-sub {
      padding-right: 0;
  }
}
@media (max-width: 767px)
{
  .search-top{
    margin-top:0px;
  }
  .file_upload_inputfile {
    flex-direction: column;
    gap: 10px;
}
    .logo-reliance {
        padding: 15px 15px 8px 15px;
    }
    .logo-reliance img {
        width: 125px;
    }
    .menu-reliance li {
      padding:3px 9px;
    }
    .custom-search-subWidth, .custom-search-subWidth-sm {
      max-width: 100%;
    }
    .breadcrumb {
      padding: 10px 15px;
    }
    .menu-reliance {
      height: 25px;
    }
}

@media (min-height: 570px)
{
    .footerWrapper {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
    
    body .sorting-wrapper {
      position: static;
    }
}
.video_audio_table td{
  white-space: nowrap;
}
.file_upload_div {
  border: 2px dashed #000;
  padding: 6px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.file_upload_innerdiv {
  border: 1px solid rgb(0 0 0 / 13%);
  background: #fff;
  box-shadow: 0px 0px 25px #00000029;
  border-radius: 10px;
  position: relative;
}
.file_upload_inputfile {
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 18px 0;
}
.file_upload_divtag {
  width: 35px;
}
.file_upload_btndiv {
  padding-left: 15px;
}
.file_upload_input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.file_upload_img {
  max-width: 100%;
  height: auto;
}
.span_drag_drop {
  color: #322F6E;
  font-size: 14px;
  opacity: 0.48;
  padding-right: 18px;
  padding-left: 18px;
}
.span_drag_drop_or {
  color: #322F6E;
  font-size: 14px;
}
.file_upload_btn {
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  font-size: 13px;
  border: none;
  padding: 13px 30px;
}
.graph-outer{
  border:1px solid #f1f1f1;
  border-radius: 5px;
  /* margin:20px 0px; */
  min-height: 380px;
  height: 100%;
  padding: 10px;
  background: #f8f8f8;
  padding: 0px 20px;
  border-radius: 5px;
}
.file_upload_table_div{
  padding: 20px;
  font-size: 14px;
}
.table_fileupload_btn {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.file_upload_cancel {
  background-color: #0f4fa1;
  border-color:#0f4fa1;
  outline: 0;
  color: #fff;
  border: 1px solid transparent;
  padding: 6px 18px;
  font-size: 14px;
  border-radius: 4px;
}
.file_upload {
  background-color: #000;
  border-color: #000;
  outline: 0;
  color: #fff;
  border: 1px solid transparent;
  padding: 6px 18px;
  font-size: 14px;
  border-radius: 4px;
}
.file_upload_table_responsive {
  overflow: auto;
  max-height: 600px;
  padding: 20px 0px 0;
}
.main-head{
  font-size: 18px;
    padding: 10px 10px;
    color: #4d4d4d;
    font-weight: 500;
    /* background: #f1f1f1; */
    border-radius: 5px;
    margin: 5px 16px;
}


.table-space{
  background: #f8f8f8;
  padding: 20px 20px;
  border-radius: 5px;
  height: 100%;

}
.graph-outer .form-head{
  padding-top: 6px;
  font-size: 14px;
  padding-bottom: 6px;
}
.file-upload{
    color: #fff;
    padding: 10px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid var(--common-button-bgcolor);
    background-color: var(--common-button-bgcolor);
    text-decoration: none;
    font-size: 14px;
}
.file-upload:hover{
  color: #fff;
  background: #0b56b8;
}
.completed-btn,.completed-btn:focus{
  background-color: rgb(176 240 190 / 47%);
  border: 1px solid #28a745;
  border-radius: 5px;
  outline: none;
}
.failed-btn,.failed-btn:focus{
  background-color: rgb(240 176 176 / 47%);
  border: 1px solid #b11010;;
  border-radius: 5px;
  outline: none;
  min-width: 70px;
}
.inprogress-btn{

}
.search-top{
  margin-top:2px;
}
/* .rHealth-main {
  width: 900px;
} */
.sorting-wrapper {
  position: absolute;
  z-index: 1;
}
 .rHealth-main-height .table-respons-scroll {
  max-height: none !important;
 }
.pdf-download-wrapper th, .pdf-download-wrapper td{
  padding: 2px 10px;
  font-size: 16px;
}

.pdf-download-wrapper th{
  font-size: 14px;
}

.two-column-table {
  column-count: 2;
}

.column-wrapper {
  flex: 1;
  column-count: 2; 
}

.table {
  margin-bottom: 0; 
}


 @media print {
  table {
    page-break-inside: auto;
  }

  thead {
    display: table-header-group;
  }

  tbody {
    display: table-row-group;
  }

  tr {
    page-break-inside: avoid;
  }
}

