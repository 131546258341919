:root {
  --theme-primary: #5F259F;
  --theme-text: rgba(124, 124, 124, 1);
  --theme-para: rgba(128, 128, 128, 1);
  --theme-heading: rgba(95, 37, 159, 1);
  --theme-blue: #3374E0;
  --btn-text: rgba(237, 237, 237, 1);
  --dark-text: #000;
  --grey-text: #8A8A8A;
  --grey-text2: #7C7C7C;
  --grey-text3: #5C5C5C;
  --banner-title: #B0B0B0;
  --light-pinkbg-keyword: #F3E7DC;
  --dark-pink-keyword: #F3DCE7;
  --dark-grey-keyword: #E7DCF3;
  --green-keyword: #DCF3E7;
  --dark-text-keywords: #494949;
  --success: #30B900;
  --theme-light-purple: #8202FF;
  --whatsapp:#0e8c07;


}

@font-face {
  font-family: 'SF-Pro-Display-Light';
  src: url('../fonts/SF-Pro-Display-Light.ttf');
}

@font-face {
  font-family: 'SF-Pro-Display-Regular';
  src: url('../fonts/SF-Pro-Display-Regular.ttf');
}

@font-face {
  font-family: 'SF-Pro-Display-Medium';
  src: url('../fonts/SF-Pro-Display-Medium.ttf');
}

@font-face {
  font-family: 'SF-Pro-Display-Semibold';
  src: url('../fonts/SF-Pro-Display-Semibold.ttf');
}

@font-face {
  font-family: 'SF-Pro-Display-Bold';
  src: url('../fonts/SF-Pro-Display-Bold.ttf');
}
@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url('../fonts/Poppins-Medium.ttf');
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.ttf');
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  background: rgba(255, 255, 255, 0.47);
}

button:focus {
  outline: 1px auto rgba(0, 0, 0, 0.2);
}

.ph-main-wrapper {
  font-size: 17px;
  font-family: 'SF-Pro-Display-Regular';
  color: var(--grey-text);
}

.ph-font-14 {
  font-size: 14px;
}

.lead {
  font-size: 24px;
}

.lead-sm {
  font-size: 20px;
  line-height: 26px;
}

.ph-btn-theme {
  cursor: pointer;
  background-color: var(--theme-blue);
  border-radius: 10px;
  padding: 20px 30px 20px 60px;
  color: var(--white);
  font-size: 17px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  border: 0;
}

/**************contact us****************/
.contactrightcontent {
  width: 90%;
}

.ph-contactrightfield {
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #ddd;
  font-size: 16px;
  text-indent: 13px;
  padding: 8px 0;
  color: #757575;
}

textarea.ph-contactrightfield {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  text-indent: 10px;
  min-height: 75px;
  max-height: 75px;
}

p.upperpara {
  width: 100%;
  font-size: 15px;
  color: grey;
}

.contactleftcontent h3 {
  font-weight: 400;
}

.contactleftcontent {
  width: 79%;
}

span.purpleCommonButton {
  background-color: var(--theme-color);
  padding: 9px 15px;
  border-radius: 20px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}

.purplecontactusbtn {
  text-align: center;
  cursor: pointer;
}

span.purpleCommonButton:hover {
  background-color: #6d6b6b;
}

.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none !important;
  user-select: none;
}

.captchaBox .captchaOverTexr {
  display: block;
  color: #393939;
  text-align: center;
  line-height: 50px;
  font-size: 1.3333333333em;
  letter-spacing: 1px;
}

.captchaBox {
  max-width: 38%;
  height: 50px;
  border: 1px solid gainsboro;
  background-image: url("../image/theme/captchaBg.png");
  background-repeat: repeat;
  margin: 10px 0;
}

.captchaBox .captchaRefresh {
  font-size: 13px;
  color: #adadad;
  clear: both;
  cursor: pointer;
  margin-top: 7px;
  width: 160px;
  float: right;
}

.captchaBox .captchaRefresh i {
  margin-left: 7px;
}

.captchaBox .captchaRefresh:hover {
  color: var(--theme-color);
}

/* *****************************************header************************** */
.ph-header {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 4;
}

.ph-header .navbar-brand {
  width: 150px;
}

.ph-header .nav_link {
  color: rgba(124, 124, 124, 1);
  font-family: 'SF-Pro-Display-Medium';
  text-decoration: none;
  font-size: 18px;
  padding: 46px 30px;
  display: block;
  text-transform: capitalize;
  font-weight: normal;
  cursor: pointer;
}

.ph-header .nav_link.active,
.ph-header .nav_link:hover {
  color: var(--theme-primary);
}

.ph-header .dropdown-toggle::after {
  display: none;
}

.ph-button {
  cursor: pointer;
  background-color: var(--theme-primary);
  border-radius: 10px;
  padding: 16px 35px;
  color: var(--btn-text);
  font-size: 17px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  border: 1px solid transparent;
}

.ph-header .ph-button {
  margin-left: 30px;
}

.ph-button:hover {
  text-decoration: none;
  color: #fff;
}
.ph-button-border, .ph-button-border:hover {
  text-decoration: none;
  cursor: pointer;
  font-family: 'SF-Pro-Display-Medium';
  background-color: transparent;
  border: 1px solid var(--theme-primary);
  border-radius: 10px;
  padding: 16px 26px;
  color: var(--theme-primary);
  font-size: 17px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}
.ph-header .dropdown .dropdown-menu {
  position: absolute;
  top: 78px;
  left: 30px;
  min-width: 170px;
  background-color: #fff;
  box-shadow: 0px 0px 4px #c3c3c3;
  padding: 10px;
  z-index: 1;
  text-align: left;
  border-radius: 0px;
  display: none;
  padding: 0;
  border: 0;
}

.ph-header .sub-menu-arrow {
  margin-left: 10px;
  transition: .5s;
}

/* .ph-header .dropdown .dropdown-menu {
  padding: 10px 0;
} */

.ph-header .dropdown .dropdown-menu .nav_link {
  padding: 5px 15px;
  font-size: 18px;
  color: #000;
  font-family: 'SF-Pro-Display-Medium';
  white-space: nowrap;
}
.ph-header .dropdown .dropdown-menu .nav_link:hover{
  background-color: #e3d3f5;
}

.ph-header .bar1,
.ph-header .bar2,
.ph-header .bar3 {
  width: 25px;
  height: 2px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.ph-header .bar-icon.menu-change .bar2 {
  opacity: 0;
}

.ph-header .menu-change .bar1 {
  transform: translate(0px, 7px) rotate(-45deg);
}

.ph-header .menu-change .bar3 {
  transform: translate(0px, -9px) rotate(45deg)
}

.ph-header .navbar-toggler {
  z-index: 2;
  border: 0;
}

.ph-header .bar-icon.menu-change>div {
  background-color: #000;
}

/* *****************************************header************************** */
/* *****************************************Home************************** */
.ph-banner-image {
  padding: 100px 40px;
  position: relative;
}

.ph-banner-heading {
  color: var(--theme-primary);
  font-size: 51px;
  font-family: 'SF-Pro-Display-Medium';
}

.ph-banner-main {
  position: relative;
  padding: 60px 0 30px;
  z-index: 2;
}

.ph-banner-main:after {
  content: "";
  position: absolute;
  right: 0px;
    bottom: 33px;
  width: 600px;
  background-image: url(../image/theme/banner.png);
  background-repeat: no-repeat;
  height: 462px;
  background-size: cover;
  /* background-position: left center; */
  z-index: -1;
}

.ph-banner-main:before {
  content: "";
  position: absolute;
  left: -100px;
  top: -80px;
  width: 500px;
  background-image: url(../image/theme/bannr-left-shade.png);
  background-repeat: no-repeat;
  height: 400px;
  z-index: -1;
  background-size: contain;
  opacity: 0.5;
}

.ph-banner-top-shade {
  content: "";
  position: absolute;
  right: 30%;
  top: -82px;
  width: 400px;
  background-image: url(../image/theme/bannr-top-shade.png);
  height: 400px;
  z-index: -1;
  background-size: contain;
  margin: auto;
  background-repeat: no-repeat;
  opacity: 0.7;
}

.ph-banner-bottom-shade {
  content: "";
  position: absolute;
  left: 13%;
  bottom: -190px;
  width: 467px;
  background-image: url(../image/theme/bannr-btm-shade.png);
  height: 450px;
  z-index: -1;
  background-size: contain;
  margin: auto;
  background-repeat: no-repeat;
}

.ph-doc-count-img {
  width: 100%;
}

.ph-proven-result-sublist {
  border: 1px solid var(--theme-primary);
  border-radius: 15px;
  min-height: 375px;
}

.ph-proven-title {
  color: var(--dark-text);
  font-size: 26px;
  font-family: 'SF-Pro-Display-Semibold';
  line-height: 36px;
}

.ph-proven-result-image {
  position: relative;
  width: 165px;
}

.ph-proven-result-count {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  height: 56px;
  font-size: 42px;
  font-family: 'SF-Pro-Display-Bold';
  color: var(--theme-primary);
  opacity: 0.8;
}

.ph-proven-result-img {
  width: 100%;
  opacity: 0.5;
}

.ph-proven-result-content {
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.ph-proven-span {
  display: inline-block;
  width: 230px;
}









































/* *****************************************Home************************** */

/* *****************************************Terms************************** */
body .terms-wrapper p,
body .terms-wrapper li,
body .terms-wrapper h3,
.terms-head,
.terms-subhead {
  color: #444;
}

.terms-head,
.terms-subhead {
  font-weight: 500;
  text-transform: uppercase;
}

.terms-head {
  text-align: center;
  font-size: 36px;
}

.terms-subhead {
  font-size: 26px;
  margin-bottom: 15px;
}

body .terms-wrapper .terms-p-bold {
  font-family: 'SF-Pro-Display-Semibold';
}

body .terms-wrapper-popup p,
body .terms-wrapper-popup li {
  font-size: 16px !important;
  line-height: 23px;
}

body .terms-wrapper-popup ul {
  padding-left: 20px;
}

/* *****************************************Terms************************** */

/* *****************************************Digital branding************************** */
.ph-main-outer {
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 5rem 0rem;
}

.ph-main-title {
  color: var(--theme-primary);
  font-size: 70px;
}

.ph-banner-title {
  color: var(--banner-title);
  font-size: 30px;
  position: relative;
  padding-bottom: 1rem;
}

.ph-banner-title::after {
  content: '';
  width: 60px;
  height: 4px;
  background: var(--theme-primary);
  position: absolute;
  bottom: -10px;
  left: 0px;
}

.ph-main-outerdiv {
  background: rgba(255, 255, 255, 1);
}

.ph-main-title-2 {
  color: var(--theme-primary);
  font-size: 60px;
  margin-bottom: 50px;
}

.ph-main-title-2-grey {
  color: var(--grey-text);
  font-size: 45px;
  margin-bottom: 10px;
}
.ph-solution-title {
  color: var(--dark-text);
  font-size: 30px;
  font-family: 'SF-Pro-Display-Semibold';
}

.ph-solution-sub {
  color: var(--theme-primary);
  padding: 16px 0px;
  font-size: 19px;
}

.ph-key-features {
  width: 50%;
  margin-bottom: 15px;
  position: relative;
  padding-left: 15px;
  display: inline-block;
  vertical-align: top;
  padding-right: 30px;
}

.ph-key-features:nth-child(odd) {
  padding-right: 35px;
}

.ph-key-features:after {
  position: absolute;
  left: 0;
  width: 4px;
  height: 13px;
  background-color: #000;
  content: "";
  border-radius: 15px;
  top: 40%;
  transform: translateY(-40%);
}

.ph-learn-more {
  background-color: var(--theme-primary);
  color: var(--white);
  border-radius: 5px;
  padding: 10px 5px;
  width: 155px;
  border: none;
  display: flex;
  justify-content: space-around;
}

.ph-learn-more:active,
.ph-learn-more:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.ph-service-img {
  position: relative;
}

.ph-card-service-title {
  color: var(--theme-primary);
  font-size: 28px;
}
.ph-space-service .card-text {
  color: rgba(39, 39, 39, 0.6);
  ;
}
.ph-explore-now,
.ph-explore-now:hover {
  width: 100%;
  background: var(--theme-primary);
  color: var(--white);
  border-radius: 5px;
  padding: 12px 20px;
  border: none;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}

.ph-explore-now-brdr,
.ph-explore-now-brdr:hover {
  background: transparent;
  color: var(--theme-primary);
  border: 1px solid var(--theme-primary);
}

.ph-explore-service {
  color: var(--theme-primary);
  font-size: 75px;
}

.ph-solutions {
  padding-left: 14px;
}

.ph-card-keywords {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
}

.ph-keywords {
  padding: 7px 13px;
  margin: 10px 17px;
  margin-left: 0px;
  border-radius: 10px;
  color: var(--dark-text-keywords);
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgb(153 139 139 / 19%);
  border: 1px solid transparent;
}
.ph-keywords:hover {
  border: 1px solid var(--theme-primary);
}

.ph-light-pink {
  background: var(--light-pinkbg-keyword);
}

.ph-dark-pink {
  background: var(--dark-pink-keyword);
}

.ph-grey {
  background: var(--dark-grey-keyword);
}

.ph-light-green {
  background: var(--green-keyword);
}

.ph-card {
  /* height: 620px; */
  border-radius: 10px;
  box-shadow: 0 0px 7px 0 rgb(133 121 121 / 20%), 0 1px 6px 0 rgb(153 139 139 / 19%);
  border: none;
  flex: 0 0 30.7%;
  max-width: 30.7%;
  margin: 1.3%;
  /* padding-bottom: 70px; */
  padding-bottom: 90px;
  padding-top: 15px;
}

.ph-card-body {
  padding: 0;
  margin-top: 20px;
}

.ph-card-success {
  border-radius: 10px;
  box-shadow: 0 0px 7px 0 rgb(133 121 121 / 20%), 0 1px 6px 0 rgb(153 139 139 / 19%);
  border: none;
  padding-bottom: 50px;
  padding-top: 15px;
  /* flex: 0 0 30.7%; */
  /* max-width: 30.7%; */
  max-width: 93%;
  margin: 2.5%;
  padding: 15px;
  /* margin: 1.3%; */
  height: 730px;
}
.ph-card-success1 {
  border-radius: 10px;
  box-shadow: 0 0px 7px 0 rgb(133 121 121 / 20%), 0 1px 6px 0 rgb(153 139 139 / 19%);
  border: none;
  padding-bottom: 50px;
  padding-top: 15px;
  flex: 0 0 31%; 
   max-width: 31%;
  margin: 10px;
}
.ph-bottom-btn {
  position: absolute;
  width: 92%;
  bottom: 18px;
  left: auto;
  right: auto;
}

.ph-space-service {
  padding: 4rem 0rem 0rem 0rem;
}

.ph-card-success-title {
  font-size: 21px;
  color: var(--theme-primary);
  font-family: 'SF-Pro-Display-Medium';
  line-height: 1;
  height: 65px;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.ph-card-success-title:hover {
  text-decoration: none;
  color: var(--theme-primary);
}
.ph-know-outcome,
.ph-know-outcome:hover {
  color: var(--theme-light-purple);
  font-family: 'SF-Pro-Display-Medium';
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
}

.ph-card-points {
  font-size: 17px;
  color: var(--dark-text);
  font-family: 'SF-Pro-Display-Medium';
}

.ph-points {
  padding: 1rem 0rem;
  font-size: 15px;
}

.ph-success-left {
  position: absolute;
  left: 25px;
  top: 20px;
  background: var(--white);
  border-radius: 5px;
  padding: 3px 7px;
  font-size: 12px;
  color: var(--success);
  font-family: 'SF-Pro-Display-Medium';
}

.ph-success-right {
  position: absolute;
  right: 25px;
  top: 20px;
  background: var(--white);
  border-radius: 5px;
  padding: 3px 7px;
  font-size: 12px;
  color: var(--dark-text);
  font-family: 'SF-Pro-Display-Medium';
}

.ph-local-pm-more {
  height: 100%;
}

.ph-local-pm-more img {
  height: 100%;
  /* height: 325px; */
  object-fit: cover;
  border-radius: 10px;
  transition: .5s ease-out;
}

/* *****************************************Digital branding************************** */
/* *****************************************Success stories************************** */
.ph-success-story-subtitle {
  font-size: 22px;
  color: var(--grey-text)
}

.ph-banner-title1 {
  color: var(--banner-title);
  font-size: 30px;
  position: relative;
  padding-bottom: 1rem;
}

.ph-banner-title1::after {
  content: '';
  width: 45%;
  height: 3px;
  background: var(--theme-light-purple);
  position: absolute;
  bottom: 0px;
  left: 35px;
}

.ph-success {
  position: relative;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;
}

.ph-serach {
  position: absolute;
  bottom: -28px;
  left: 25%;
  width: 51%;
}

.ph-search-icon {
  left: 29px;
  position: absolute;
  top: 50%;

  transform: translate(-50%, -50%);
}

.ph-search-button {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(-2%, -50%);
}

.ph-serach input::placeholder {
  font-size: 20px;
  margin-left: 30px;
}

.ph-position {
  position: relative;
}

.ph-serach input {
  height: 65px;
  padding-left: 50px;
}

.ph-search-btn {
  background-color: var(--theme-primary);
  padding: 10px 50px;
  color: var(--white);
  border: none;
  border-radius: 10px;
}

.ph-success-story-subtitle {
  padding-bottom: 20px;
  letter-spacing: 0.5px;
}

/* *****************************************Success stories************************** */
/* *****************************************footer************************** */
.ph-footer-icons {
  margin: 0px 6px;
}

.ph-footer {
  margin-top: 20px;
  padding: 40px 40px;
  border-top: 2px solid #cbbce6;
}

.ph-main-wrapper .ph-footer {
  margin-top: 0px;
}

.ph-reg-address {
  font-size: 17px;
  color: var(--dark-text);
  font-family: 'SF-Pro-Display-Semibold';
  padding-bottom: 5px;
}

.ph-main-address {
  font-size: 17px;
  color: var(--grey-text);
  width: 315px;
}

.ph-nav-item {
  font-size: 18px;
  color: var(--grey-text2);

}

footer .ph-links-footer {
  color: var(--dark-text);
  font-size: 18px;
  padding-bottom: 10px;
  font-family: 'SF-Pro-Display-Semibold';
}

.ph-footer-logo {
  padding-bottom: 3rem;
  padding-top: 10px;
}

.ph-footer-right {
  padding-top: 10px;
}

.ph-nav-item:hover {
  color: var(--theme-primary);
}

.ph-pl-0 {
  padding-left: 0px;
}

.ph-footer-with-logo {
  text-align: right;
}

.ph-footer-with-logo .nav {
  justify-content: end;
}

.ph-footer-navs {
  margin-bottom: 0.75rem;
}

/* *****************************************footer************************** */




































































































/* *******************************************about***************************** */
.ph-about-banner {
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.1);
}

.ph-about-title {
  color: var(--dark-text);
  font-size: 45px;
  font-family: 'Poppins-Regular';
  width: 95%;
}

.ph-about-title-span {
  color: var(--theme-primary);
}

.ph-about-here-outer {
  border: 1px solid var(--theme-primary);
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin-top: 30px;
}

.ph-about-here {
  padding-top: 60px;
}

.ph-about-here-img {
  background: rgba(255, 239, 215, 1);
  border-radius: 15px;
}

.ph-about-fluid {
  margin: auto;
  display: block;
}

.ph-our-team-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
}

.ph-ourteam {
  padding-top: 60px;
}

.ph-our-team-inner-div {
  box-shadow: 0px 0px 9.200003623962402px 0px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 15px;
  width:100%;
  /* margin: 10px 0px;
    height: 400px; */
    
}

.ph-our-team-title {
  color: var(--dark-text);
  font-size: 17px;
  font-family: 'Poppins-Bold';
  margin-bottom: 5px;
}

.ph-our-team-linkedin {
  color: var(--theme-blue);
  font-family: 'Poppins-Medium';
  font-size: 13px;
  cursor: pointer;
}

.ph-our-team-linkedin:hover {
  text-decoration: none;
}

.ph-our-team-inner-head {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 15px;
  align-items: center;
}

.ph-our-team-img {
  width: 60px;
}

.our-team-inner-container {
  margin-top: 10px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  color: var(--grey-text3);
}

.ph-take-next {
  padding-top: 120px;
  padding-bottom: 50px;
  position: relative;
}

.ph-take-title {
  text-align: center;
  font-family: 'SF-Pro-Display-Bold';
  color: #000;
  font-size: 51px;
  width: 75%;
  margin: auto;
  margin-bottom: 10px;
}

.ph-take-subtitle {
  text-align: center;
  /* color: var(--theme-primary); */
  font-size: 23px;
  font-family: 'SF-Pro-Display-Semibold';
  color: #7e57c2;
}

.ph-take-btn {
  background-color: var(--theme-primary);
  color: var(--white);
  margin: auto;
  display: block;
  padding: 15px 50px;
  border: 0;
  border-radius: 8px;
  font-size: 20px;
  margin-top: 40px;
  font-family: 'SF-Pro-Display-Regular';
}

.ph-take-bg-img {
  position: absolute;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.ph-take-bg-img01 {
  background-image: url(../image/theme/icon04.png);
  width: 66px;
  height: 76px;
  left: 20%;
  bottom: 150px;

}

.ph-take-bg-img02 {
  background-image: url(../image/theme/icon02.png);
  width: 65px;
  height: 100px;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
}

.ph-take-bg-img03 {
  background-image: url(../image/theme/icon03.png);
  width: 101px;
  height: 60px;
  left: 10%;
  bottom: 0;
}

.ph-take-bg-img04 {
  background-image: url(../image/theme/icon05.png);
  width: 101px;
  height: 90px;
  right: 21%;
  top: 52%;
}

.ph-take-bg-img05 {
  background-image: url(../image/theme/icon07.png);
  width: 60px;
  height: 94px;
  right: 0;
  top: 55%;
}

.ph-take-bg-img06 {
  background-image: url(../image/theme/icon08.png);
  width: 101px;
  height: 60px;
  right: 6%;
  bottom: 0;
}

.ph-take-bg-img07 {
  background-image: url(../image/theme/icon09.png);
  width: 90px;
  height: 100px;
  left: 7%;
  top: 75px;
}

.ph-take-bg-img08 {
  background-image: url(../image/theme/icon10.png);
  width: 81px;
  height: 100px;
  right: 11%;
  top: 139px;
}
/* *******************************************about***************************** */
/* *******************************************case studt************************ */
.ph-case-study-banner {
  padding-top: 40px;
}

.ph-case-study-inner-banner {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 10px;
}

.ph-case-study-title-1 {
  color: var(--theme-primary);
  font-size: 30px;
  font-family: 'SF-Pro-Display-Regular';
}

.ph-case-study-btn-div {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.ph-case-study-btn {
  border: 1px solid rgba(255, 181, 115, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  color: rgba(73, 73, 73, 1);
  background: #fff;
  font-family: 'SF-Pro-Display-Regular';
  font-size: 16px;
  border-radius: 6px;
  padding: 10px 25px;
}

.ph-dot {
  width: 5px;
  height: 5px;
  background-color: rgba(73, 73, 73, 1);
  margin-right: 5px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.ph-case-study-inner-container {
  margin-top: 40px;
}
.ph-lg-space{
  padding: 0px 250px;
}
.ph-text-decoration,
.ph-text-decoration:hover {
  text-decoration: none;
}

.invisible-class {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-out;
}

.visible-class {
  max-height: 500px;
  transition: max-height 1s ease-out;
}

.isspan {
  transition: 1s ease-out;
}

.isspanHide {
  transition: .5s ease-out;
  opacity: 0;
  /* display: none; */
  font-size: 0px;
  display: contents;
}

.isspanShow {
  transition: .5s ease-out;
  opacity: 1;
  font-size: 17px;
}

.ph-learn-more.ph-btn-show {
  width: 50px;
}

.ph-learn-more {
  transition: .5s ease-out;
}

.ph-icon-wrapper {
  margin-left: -18px;
}

.ph_sub_arrow {
  display: none;
  width: 13px;
  margin-right: 10px;
  filter: invert(1);
}

/* *******************************************case studt************************ */

.slick-next,.slick-prev{
display: none !important;
}

a.solution-cards:hover{
  text-decoration: none;
  color: var(--grey-text);
}

a.solution-cards{
  color: var(--grey-text);
}
.ph-whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999999;
  cursor: pointer;
  text-decoration: none;
}
.ph-whatsapp-icon:hover{
  text-decoration: none;
}
/* .ph-whatsapp-icon img {
  height: 60px;
} */
.whatsapp{
  padding: .4857rem 1.57143rem;
  border-radius: 999rem;
  background-color: #075E54 ;
  color: #FFFFFF ;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 6px 4px rgb(0 0 0 / 10%);
    outline: 3px solid #fff;
}
.ph-news-details-main{
width:70%;
margin:0 auto;
}
.ph-news-details-main .ph-points{
  font-size: 20px;
  color:#262626;
}
.ph-news-main-head{
  font-size: 28px;
  color:var(--theme-primary);
  font-family: 'SF-Pro-Display-Bold';
  line-height: 28px;
  padding-bottom: 25px;
}
.ph-news-admin{
  width:70%;
  margin:0 auto;
  text-align: left;
  padding-top: 100px;
}
.ph-news-date{
  color:#7C7C7C;
  padding: 11px 0px;
  font-family: 'Poppins-Medium';
}
.ph-news-linkdn{
color:#5762C2;
text-decoration: underline;
font-family: 'Poppins-Medium';
}
.ph-news-linkdn a,.ph-news-linkdn a:hover{
  color:#5762C2;
}
.ph-news-authorname{
  color:#7E57C2;
  font-family: 'Poppins-Bold';
  padding-left: 10px;
}
.ph-news-author{
  color:#B0B0B0;
  font-family: 'Poppins-Medium';
}
.ph-newsletter{
  color:var(--theme-primary);
  font-size:29px;
  font-family: 'SF-Pro-Display-Bold';
  padding-top: 30px;
  margin-bottom: 10px;
}
.blog-sub-head{
  font-size:18px;
  font-family: 'SF-Pro-Display-Bold';
}
.ph-mobile-div{
padding: 20px;
border-radius: 15px;
box-shadow: 0 0px 7px 0 rgb(133 121 121 / 20%), 0 1px 6px 0 rgb(153 139 139 / 19%);
}
.news-details-img{
  width:500px;
  margin:0 auto;
}
.cursor-pointer{
  cursor: pointer;
}
.card-text{
  text-align: left;
}
.ph-purple{
  color:var(--theme-primary);
}
.ph-points-details{
  padding-top: 30px;
}
.blog-inner-head{
  font-size: 22px;
}
.ph-main-details{
  padding-top: 20px;
}
.ph-main-details .card-text{
  opacity: .8;
}
.ph-main-details ul{
  padding-left: 8px;
  list-style: none;
}
.ph-main-details li{
  font-size: 20px;
  color: #262626;
  opacity: .8;
  margin-bottom: 1rem;
  display: flex;
}
.blog-span-head{
  font-family: 'SF-Pro-Display-Bold';
  color: var(--theme-primary);
}
.blog-span-dark{
  color: var(--dark-text);
}
span.blog-span-head{
  margin-right: 2px;
}
.news-inner-head,.news-head-inner{
  font-size: 22px;
  font-family: 'SF-Pro-Display-Bold';
}
.news-span-head{
  font-family: 'SF-Pro-Display-Bold';
  color: var(--dark-text);
  margin-right: 2px;
}
.news-ul-tag{
  padding-left: 8px;
  list-style: none;
}
.ph-referral_div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  text-align: center;
  padding: 50px 30px;
  border-radius: 0px;
  font-family: 'SF-Pro-Display-Medium';
  color: var(--dark-text);
  /* background-color: #f1f1f1; */
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ph-referral_div .sp-banner-p {
  font-family: 'OpenSans-SemiBold';
}
.ph-referral-head{
  font-size: 25px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.ph-btn-referral {
  color: #fff;
  text-transform: capitalize;
  border: 0;
  font-size: 14px;
  padding: 10px 60px;
  border: 1px solid black;
  background-color: black;
  border-radius: 20px;
  transition: .5s;
}

.ph-fullcontactus {
  width: 90%;
  margin: auto;
  padding: 25px 0 0px;
  clear: both;
}
.ph-contactleftcontent p {
  font-size: 20px;
}
.ph-contactleftcontent h3 {
  font-weight: 400;
}
.ph-purplehealthfeaturehead {
  text-align: center;
  font-size: 44px;
  font-weight: 100;
  padding: 53px 0 5px;
  /* float: left; */
  width: 100%;
  line-height: 1.3;
}
.ph-right-center{
  display: flex;
  align-items: center;
}
.ph-border-img{
  border: 1px solid #e9e6e6;
    padding: 14px;
    border-radius: 10px;
}
.ph-border-img {
  height: 410px;
  transition: .5s ease-out;
}
.ph-border-img .ph-local-pm-more img.pdt-long{
  height: 380px;
  transition: .5s ease-out;
}
.ph-local-pm-more img.pdt-long {
  height: 325px;
  object-fit: cover;
  border-radius: 10px;
  transition: .5s ease-out;
}

.table-respons-scroll{
  max-height: 500px;
}
.table-respons-scroll thead{
  position: sticky;
  top: 0;
  background-color: #f8f8f8;
}
/* width */
.table-respons-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.table-respons-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.table-respons-scroll::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.table-respons-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.table-respons-scroll th{
  min-width: 150px;
}
.table-space{
  border: 1px solid #f1f1f1;
}
.file-upload:hover{
  text-decoration: none;
}
.dropdown-submenu-div {
  position: absolute;
  left: 100%;
  top: 10px;
  z-index: 999;
  display: flex;
  font-size: 18px;
  /* box-shadow: 0px 0px 4px #c3c3c3; */
  width: 0;
  overflow: hidden;
  padding: 10px 0; /* Adjusted for initial padding */
  transition: width 0.5s ease, padding 0.5s ease, opacity 0.5s ease;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
}

.dropdown-submenu-div ul li {
  list-style: none;
  cursor: pointer;
  font-family: 'SF-Pro-Display-Semibold';
}

.dropdown-submenu:hover .dropdown-submenu-div,
.dropdown-submenu:focus-within .dropdown-submenu-div {
  visibility: visible;
  opacity: 1;
  width: auto; 
  /* padding: 10px;  */
  position: relative;
  left: unset;
  top: unset;
  padding: 5px 0;
  padding-left: 53px;
}

.benefits-section, .plan-section{
  background-color: #ecf0fc;
}
.benefits-head{
  font-size: 21px;
    color: #000;
    font-family: 'SF-Pro-Display-Medium';
    padding-bottom: 10px;
}
.plan-list {
  border-radius: 10px;
  border: 0;
  height: 100%;
  position: relative;
}
.plan-list-ul li {
  list-style-image: url("../image/icons/tick-list.png");
  font-size: 14px;
  color: var(--dark-text-keywords);
  font-family: 'SF-Pro-Display-Medium';
  padding: 5px 0px;
}
.plan-list-head{
  text-align: center;
  background-color: #ccd9fc;
  /* padding: 20px; */
  border-radius: 5px;
  color: #000;
  font-size: 20px;
  font-family: 'SF-Pro-Display-Medium';
  min-height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.plan-list-rate {
  height: 45px;
  font-weight: 500;
  text-align: center;
}
.plan-list-rate .badge{
  background-color: #fff;
  font-size: 16px;
  color: #000;
  font-family: 'SF-Pro-Display-Medium';
}
.benefits-img{
  padding-bottom: 10px;
}
.key-features-div{
  display: flex;
  gap: 10px;
}
.key-features-icon{
  width: 40px;
  flex: none;
}
.key-features-content{
  width: calc(100% - 50px);
}
.key-feature-head{
  color: var(--theme-primary);
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 6px;
}
.key-feature-para{
  font-size: 14px;
  padding-right: 102px;
}
.medical_special{
  color: var(--theme-primary);
  font-size: 14px;
  margin-top: auto;
  padding-left: 32px;
}
.dropdown-submenu:hover{
  background-color: #e3d3f5;
}
.plan-list-head-small{
  font-size: 13px;
}