@media (max-width: 1199px) {
    .freecms_featureshead.freecms_freeforever{
        font-size: 30px;
    }
    .hspinfo-contentdiv label {
        width: 132px;
    }
    .superadminDivBox label {
        width: 145px;
    }
    .offcehrDayFieldDiv label {
        width: 145px;
    }
    .seofieldDivhsp label {
        width: 95px;
    }
    .hsplink-field label {
        width: 100px;
    }
    .admindctr-specialzation{
        width: 225px !important;
    }
    .addbanerhspOuterDiv {
        margin: 30px 15px;
    }
    .addbannerhsp-contentdiv{
        width: 50%;
    }
    .addbanr-hspimgaeuploadDiv{
        width: 101px;
    }
    .addbannerhsp-contentdiv-upload{
        width: 35%;
    }
    .hspworkdetails-wrappermargin {
        margin-top: 12%;
    }
    .offcehrDaydiv label{
        width: 110px;
    }
    .react-date-dctr-widthiput .react-datepicker__input-container input {
        width: 264px !important;
    }
}

@media (max-width:1024px) {
    .customizationPop {
        width: 50% !important;
        top: 7% !important;
    }
    /* = ===== css begins =======  */
    .smssetngsWrap {
        width: 940px;
        margin: 20px auto;
    }
    .smsSetngHead {
        width: 98%;
    }
    .smssetngscontentdiv .smsItem .react-datepicker-popper {
        top: 3% !important;
        left: 22% !important;
    }
    .health-track-card-right .tbody {
        width: 530px;
    }
    .health-track-card-right.hlth-immunetb table th:nth-child(4),
    .health-track-card-right.hlth-immunetb table td:nth-child(4),
    .health-track-card-right.hlth-immunetb table th:nth-child(5),
    .health-track-card-right.hlth-immunetb table td:nth-child(5) {
        width: 38px;
    }
    .health-track-card-right .tbody.babymilestne {
        width: 445px;
    }
    .health-track-card-right table th,
    .health-track-card-right table td {
        width: 45%;
    }
    .health-track-card-right.hlth-trck-pdf table th:first-child {
        width: 45%;
    }
    /* .input.hspInfofieldinput, select.hspInfofieldinput{
       width: 264px;
    } */
    .hspinfo-contentdiv label,
    .offcehrDayFieldDiv label,
    .superadminDivBox label {
        /* width: 155px; */
        width: 130px;
    }
    .offcehrDayFieldDiv {
        width: auto;
    }
    /* .hspinfo-contentdivwidthres{
        width: 46% !important;
    } */
    .superadminDivBoxwidtAuto {
        width: auto;
    }
    .Infoinputwidthdoctr {
        width: 260px !important;
    }
    .doctorhspwrkdetal-ofcehour {
        width: 80%;
    }
    .eductninputwidthdctr {
        width: 180px !important;
    }
    .priceinputproduredetl {
        width: 50px !important;
    }
    .seofieldDivhsp label {
        width: 80px;
    }
    .hsplink-field label {
        width: 100px;
    }
    .admindctr-specialzation {
        width: 215px !important;
    }
    .addbannerhsp-contentdiv{
        width: 50%;
    }
    .addbanerhspOuterDiv {
        margin: 30px 15px;
    }
    .addbanr-hspimgaeuploadDiv {
        width: 102px;
    }
    .addbannerhsp-contentdiv-upload{
        width: 35%;
    }
    .popup-contentDiv{
        width: 85%;
        top: 30%;
    }
    .special-popuptextareawidth {
        width: 84%;
    }
    .attachment-cancelImg{
        right: 11px;
    }
    .label-smssetngbold {
        width: 170px !important;
    }
    .adrsmap-hspinput{
        width: 50%;
    }
    .adrsmap-hspadmin {
        width: 36% !important;
        /* height: 26% !important; */
        right: 10%;
    }
    .hspworkdetails-wrappermargin {
        margin-top: 14%;
    }
    .site-inputfield-width input{
        width: 264px !important;
    }
    .date-healthinfo-header input{
        padding: 0 !important;
    }
}

@media (max-width:992px) {
    .main-outer {
        width: 100%;
    }
    .container-auto {
        max-width: 100%;
    }
    .purplehealthfeaturehead {
        font-size: 36px;
        font-weight: 400;
    }
    /*** Login ***/
    .adminHspM-loginfield input {
        width: 250px;
    }
    .adminHspM-loginDiv {
        width: 100%;
    }
    /*** Dashboard ***/
    .admindashbord-Div {
        padding: 10px 15px;
    }
    .admin-dashBtns {
        width: 40%;
    }
    .adminMangemntdc-dashboard {
        width: 100%;
    }
    .admin-dc-dashBtns {
        width: 30%;
    }
    .videocall_div{
        width: 50%;
    }
    .freecmstwo .freecms_sliderpara{
        width: 100%;
        padding: 20px 0;
    }
    .freecmstwo .freecms_container {
        padding-bottom: 100px;
    }
    .drRegistrationForm .subBx{
        width: 100%;
    }
    .serviceWrap ul li {
        width: 31%;
    }
    .priceCover .popField {
        width: 130px;
        display: block;
        margin: auto;
    }
    .practiceTimelineDivision {
        width: 90%;
    }
    .searchbtn-hspMng{
        width: 50px;
        font-size: 12px;
    }
    .tableHeader-HspMangemnt input[type="file" i]{
        font-size: 12px;
    }
    .searchbtn-hspMng.export_excel {
        width: 85px;
    }
    .customizationPop {
        width: 60% !important;
    }
    .adminMangemntdc-dashboard {
        width: 100%;
    }
    .admin-dc-dashBtns {
        width: 30%;
    }
    .smssetngscontentdiv .smsItem input[type="text"],
    .smssetngInput {
        font-size: 12px;
        width: 60%;
    }
    .smssetngscontentdiv {
        width: 100%;
    }
    .smssetngscontentdiv .smsItem label {
        font-size: 12px;
        width: 204px;
    }
    .smssetngsWrap {
        width: 100%;
        margin: 10px auto;
    }
    .smssetngsTop label {
        font-size: 14px;
    }
    .admin-dashBtns {
        width: 33%;
    }
    .admin-dashBtns a {
        font-size: 14px;
    }
    .pagination-HspList ul li a {
        height: 28px;
        min-width: 28px;
        font-size: 12px;
        padding: 5px 6px;
        margin: 0 2px;
    }
    .AddNewHspbtnDiv {
        width: 26%;
    }
    .pagination-HspList {
        width: 74%;
    }
    .pagination-HspList {
        width: 100%;
    }
    .offcehrDay select {
        width: 95px;
    }
    .offcehrDaydiv label {
        width: 89px;
    }
    .offcehrDayFieldDiv label {
        width: 118px;
    }
    .offcehrDayFieldDiv select {
        width: 179px;
    }
    .table-bordered-hspSpecl th,
    .table-bordered-hspSpecl td {
        width: 245px;
    }
    .tdtxtLabel-hsp {
        font-size: 12px;
    }
    select.hspInfofieldinput {
        width: 259px;
    }
    .superadminDivBox {
        width: auto;
    }
    .serviceselectHsp {
        width: 155px !important;
    }
    .serviceselectHspMob-timeservicdetl {
        width: 95px !important;
    }
    .seofieldDivhsp-textarea {
        width: 630px;
        min-width: auto;
    }
    .seofieldDivhsp label {
        width: 100px;
        text-align: left;
    }
    /* .linkhspGenerateBtn-div{
       margin-left: -29px;
   } */
    .linkhspGenerateBtn {
        margin-left: -22px;
    }
    .ph-add-images .yes {
        width: 250px;
    }
    .doctorhspwrkdetal-ofcehour {
        width: 95%;
        padding-bottom: 23px;
    }
    .eductninputwidthdctr {
        width: 110px !important;
    }
    .hspinfo-contentdiv {
        width: 100%;
    }
    .hsplink-field label {
        width: 155px;
    }
    .health-track-card-right table th,
    .health-track-card-right table td {
        width: 100%;
    }
    .admindctr-specialzation {
        width: 165px !important;
    }
    .addrepOuter{
        width: 100%;
    }
    .addbannerhsp-contentdiv {
        width: 70%;
    }
    .addbanr-hspimgaeuploadDiv {
        width: 107px;
        margin-bottom: 10px;
    }
    .addbannerhsp-contentdiv-check {
        width: 33% !important;
    }
    .hspbnradddiv-mob{
        width: 100%;
    }
    .addbannerhsp-contentdiv-upload {
        width: 50%;
    }
    .popup-contentDiv {
        width: 95%;
        top: 40%;
    }
    .special-popuptextareawidth {
        width: 91%;
    }
    .servcelabel-hspwidth {
        width: 75px !important;
    }
    .attachment-cancelImg {
        right: 0px;
    }
    .attchment-adddiv {
        width: 18%;
    }
    .lanlongdiv{
        margin-top: 23%;
    }
    .hspworkdetails-wrappermargin{
        margin-top: 35%;
    }
    .adrsmap-hspadmin {
        width: 82% !important;
        /* height: 11% !important; */
        right: 9%;
        margin-top: 23%;
    }
    .hspworkdetails-wrapper-margintop{
        margin-top: 200px;
    }
    .doctr-wraper-border{
        padding: 10px;
    }
    .popupinput-widthproduct {
        width: 245px;
    }
    .date-healthinfo-header{
        width: 80px;
    }
    .slecthsp {
        width: 120px;
    }
    .search-btn-report{
        width: 100%;
    }
    .poll-report-chart,.poll-report-view{
        width: 100%;
        padding-left: 0;
    }
    .hspinfo-btnupload-w {
        font-size: 12px;
    }
}

@media (max-width:767px) {
    .count-report {
        font-size: 20px;
    }
    .header-h1 {
        font-size: 20px;
    }
    .header-date {
        font-size: 14px;
    }
    .graph-line {
        padding-right: 25px;
    }
    .border-blue {
        margin-right: 10px;
    }
    .main-outer {
        width: 90%;
    }
    .conf-cursorpointer {
        width: auto;
        height: auto;
    }
    .sp-banner-content{
        width:95%;
    }
    .feedback-form {
        width: 100%;
    }
    .desk-img{
        display: none!important;
    }
    .mbl-img{
        display: block!important;
    }
    .row-margin {
        margin-bottom: 15px;
    }
    textarea.textarea {
        font-size: 18px;
    }
    .practiceTimelineWrap .practiceTitleTop span {
        width: 100%;
        font-size: 12px;
    }
    /*** Login ***/
    .adminHspM-loginfield {
        margin-bottom: 10px;
    }
    .adminHspM-loginfield label {
        width: 100%;
        text-align: left;
        margin-bottom: 0;
    }
    .adminHspM-loginfield input {
        width: 100%;
    }
    .adminHspM-loginOuter {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    /*** Dashboard ***/
    .admindashbord-Div {
        padding: 10px 15px;
    }
    .sectionTag-admindash {
        padding: 13px 25px;
        font-size: 14px;
        margin: 8px 10px;
    }
    .admin-dashBtns {
        width: 100%;
        margin: 6px auto;
    }
    .admin-dc-dashBtns {
        width: 75%;
    }
    /* ** Add journal** */
    .formButton-divright {
        text-align: center;
    }
    .videocall_div{
        width: 80%;
    }
    .videocall_header{
        font-size: 14px;
    }
    .videocall_body{
        font-size: 13px;
    }
    .videocall_footer button{
        font-size: 11px;
        padding: 4px 8px;
    }
    .videocall_footer,.videocall_header{
        padding: 4px 15px 4px;
    }
    .freecms_sliderleft,.freecms_sliderright{
        width: 100%;
    }
    .freecms_container{
        padding-top: 20px;
        padding-bottom: 0px;
    }
    .freecms_sliderhead{
        font-size: 30px;
    }
    .freecms_slidersubhead,.freecms_sliderpara{
        font-size: 25px;
    }
    .freecms_sliderleft{
        padding-bottom: 20px;
        padding-right: 0;
    }
    .freecms_left_user{
        width: 320px;
    }
    .freecms_input input,.freecms_input button, .freecms_joinnow{
        padding: 10px 10px;
        font-size: 14px;
    }
    .freecms_sliderright{
        padding-bottom: 140px;
    }
    .freecms_featureshead{
        font-size: 20px;
    }
    .freecms_features_key{
        width: 100%;
    }
    .freecms_features_img{
        width: 30px;
    }
    .freecms_features_connt{
        width: calc(100% - 30px);
        padding-left: 30px;
    }
    .freecms_joinnow{
        margin-top: 40px;
        width: 100%;
    }
    .freecms_features{
        padding: 20px 15px;
    }
    .freecms_apponint_head,.freecms_apponint_para{
        font-size: 18px;
    }
    .freecmstwo .freecms_container {
        padding-bottom: 100px;
    }
    .freecms_inr_inner{
        font-size: 30px;
    }
    .freecms_innercard{
        padding: 20px 0;
    }
    .feecms_editinnercard .freecms_featureshead {
        font-size: 25px;
    }
    .freecms_editinput{
        padding: 0 20px;
    }
    .freecms_editrequired{
        font-size: 12px;
    }
    .drRegistrationForm .form-section .label-text-cln:after {
        content: "" ;
    }
    .input-design-widthclinic {
        width: 84% !important;
    }
    .labelcolumnaftr::after {
        content: ":";
    }
    .drRegistrationForm .bg-border {
        width: 100%;
    }
    .drRegistrationForm .input-design{
        margin-bottom: 0;
    }
    .drRegistrationForm .form-head{
        font-size: 14px;
    }
    .drRegistrationForm .labelcolumnaftr::after{
        content: " ";
    }
    .drRegistrationForm {
        padding: 20px 5% !important;
    }
    .drRegistrationForm .label-text{
        margin-bottom: 10px !important;
        font-size: 12px;
    }
    .textleftAlign {
        width: 75%;
    }
    .label-reg-padd {
        padding-left: 0;
    }
    .label-reg-displytxt {
        font-size: 12px;
        display: contents;
    }
    .drRegistrationForm .spanRight {
        margin-right: 0px;
        width: 100%;
    }
    .drRegistrationForm .spanLeft{
        width: 100%;
    }
    .subBx {
        display: block;
    }
    .doctlistInputmob {
        width: 80px;
    }
    .slecthspMob {
        width: 90px !important;
    }
    .tableHeader-HspMangemnt {
        height: 44px;
    }
    .qualifcatin-popuplabel {
        width: 95px;
    }
    .qualifcatin-popupInput {
        width: 170px;
    }
    .customizationPop {
        width: 85% !important;
    }
    .closepopupqual {
        right: 6px;
    }
    .breadcrum-adminMangmnt a {
        font-size: 11px;
    }
    .breadcrum-adminMangmnt a {
        padding: 6px 14px;
    }
    .pagination-qualList {
        width: 100%;
        text-align: left;
    }
    .qual-popupdivouter {
        width: 100%;
    }
    .pagination-qualList ul li a {
        width: 30px;
        height: 30px;
    }
    .pagination>li>a,
    .pagination>li>span {
        margin: 0 2px;
    }
    .searchbtn-hspMng {
        width: 100%;
        margin-top:5px;
    }
    .searchbtn-hspMng.export_excel{
        width: 100%;
    }
    /*  =========== css  begins ========== */
    .admin-dc-dashBtns {
        width: 75%;
    }
    .admin-dc-dashBtns a {
        font-size: 14px;
    }
    .smssetngsTop {
        width: 100%;
    }
    .smssetngsTop label {
        font-size: 14px;
    }
    .smssetngsTop label {
        width: 100%;
        text-align: left;
        font-size: 13px;
    }
    .smssetngsTop select {
        width: 100%;
        font-size: 13px;
    }
    .smsSetngHead {
        width: 100%;
        padding-top: 15px;
    }
    .smssetngscontentdiv {
        width: 100%;
        padding: 0px;
    }
    .smssetngscontentdiv .smsItem label {
        width: 100%;
        text-align: left;
    }
    .smssetngscontentdiv .smsItem input[type="text"] {
        width: 100%;
    }
    .smssetngCheckbox .smsItem label {
        width: 60%;
    }
    .smssetngInput {
        width: 100%;
    }
    .admin-dashBtns {
        width: 70%;
    }
    .pagination-HspList ul li a {
        height: 26px;
        min-width: 26px;
        padding: 3px 6px;
        margin: 3px 2px;
    }
    .AddNewHspbtnDiv {
        width: 100%;
    }
    .slecthspMob {
        width: 106px;
    }
    .hspmngInputmob {
        width: 139px;
    }
    .pagination-HspList ul {
        margin-bottom: 0;
    }
    .addnewhspbtn {
        margin-bottom: 4%;
    }
    .head-hospMangmntList {
        margin-bottom: 3%;
        font-size: 16px;
    }
    .hspinfo-contentdiv label,
    .offcehrDayFieldDiv label,
    .superadminDivBox label {
        width: 100%;
        text-align: left;
    }
    .hspwrkdetal-ofcehour {
        width: 100%;
    }
    .hspworkdetails-wrapper {
        padding: 5px 5px;
    }
    .offcehrDayFieldDiv select {
        width: 268px;
    }
    .hsptimefieldDivMob-time select {
        width: 84px;
    }
    .hsptimefieldDivMob-time label {
        width: 50px;
        font-size: 13px;
    }
    /* .serviceselectHspMob{
       width: 260px !important;
   } */
    .serviceslabelHspMob {
        width: 90px !important;
        font-size: 13px !important;
    }
    /* .serviceselectHspMob-timeservicdetl{
       width: 73px !important;
   } */
    .hspManagement-GoogleMap iframe {
        padding-bottom: 0;
    }
    .hspInfoSubhead span {
        color: red;
        font-size: 20px;
    }
    .hspInfoSubhead {
        font-size: 20px;
    }
    .breadcrum-adminMangmnt a {
        font-size: 10px;
        padding: 5px 15px;
    }
    .seofieldDivhsp-textarea {
        width: 100%;
        min-width: auto;
    }
    .hspInfofieldinputMobmap {
        width: 156px;
    }
    .linkhspgenerteInput {
        width: 89%;
        margin: 0 4px 5px;
    }
    .linkhspGenerateBtn {
        width: 277px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .linkhspgenerteInput {
        width: 100%;
        margin-top: 5px;
        margin-left: 0;
    }
    .rmvbtn-imgeuplodHsp {
        right: 38px;
        top: 14px;
    }
    .ph-uploadedimg img {
        width: 60%;
        margin: 6px;
    }
    .ph-imglist .smssetng-btn {
        text-align: left;
    }
    .ph-uploadedimg {
        width: 85%;
        text-align: center;
        margin: auto;
    }
    .ph-addimagewraper .smssetng-btn {
        text-align: center;
    }
    .hidden-menuImgUplod {
        height: 244px;
        min-width: auto;
        width: 100%;
    }
    .doctorhspwrkdetal-ofcehour {
        width: 100%;
        padding-bottom: 23px;
    }
    .doctroffcehrDaydiv label {
        width: 59px;
        font-size: 13px;
    }
    .doctroffcehrDaydiv select {
        width: 49px;
        height: 32px;
    }
    .sheduledivdoctr {
        width: 49%;
        text-align: right;
        font-size: 13px;
    }
    .doctorofcrhr-contentdiv .label {
        width: 120px;
    }
    .doctorofcrhr-contentdiv select {
        width: 169px;
    }
    .educationdoctrlabelwidth {
        width: 99px !important;
        text-align: left !important;
    }
    .eductninputwidthdctr {
        width: 160px !important;
        padding-left: 7px;
    }
    .educationlabelwidthdctr {
        width: 81px !important;
        text-align: left !important;
    }
    .educationlabelwidthyear {
        width: 89px !important;
        padding-left: 7px;
        text-align: left !important;
    }
    .hsptimefieldDivMob-time label {
        width: 90px;
        /* text-align: left; */
    }
    .smssetng-cancelbtn {
        padding: 8px 14px;
        font-size: 12px;
    }

    .smssetng-svebtn {
        font-size: 12px;
        padding: 8px 26px;
    }
    .procedufleupload-width {
        width: auto !important;
    }
    .hsptimefieldDivMob-time {
        width: 100%;
    }
    .educationdoctr-contentdiv {
        width: 100%;
    }
    .procedreouterPadng {
        padding: 0 !important;
    }
    .prInpwidthMob {
        width: 84px !important;
    }
    .hsptaltimeHead {
        padding: 5px 15px;
    }
    .procedhsp-contentdiv {
        width: auto;
    }
    input.proinputMobwidt {
        width: 211px;
    }
    .addHspPage-Wrapper .react-tabs__tab {
        margin-top: 7px;
        padding: 5px 13px;
        margin-right: 5px;
        font-size: 12px;
    }
    .select.hspInfofieldinput {
        width: 100%;
    }
    /* input.hspInfofieldinput {
        width: 100%;
    } */
    select.hspInfofieldinput {
        width: 100%;
    }
    .superadminDivBox {
        width: 100%;
    }
    .hspinfo-adminselct-mob label {
        width: 52%;
    }
    .offcehrDay select {
        width: 80px;
    }
    .addimgeDivHsp {
        position: absolute;
        right: 25px;
    }
    .seofieldDivhsp-textarea {
        font-size: 12px;
        text-indent: unset;
    }
    .linkhspGenerateBtn {
        margin-left: 0;
    }
    .profield-div label {
        width: 104px;
    }
    .admindctr-specialzation {
        width: 100% !important;
    }
    .admindctr-specialzation-tr{
        border-bottom: none;
    }
    .offcialinfowrapperhsp-bg{
        padding: 4px 20px 40px;
    }
    .addrep-contentdiv {
        padding-left: 15px;
        width: 90%;
    }
    .addrep-btn{
        margin-right: 0;
        text-align: center;
    }
    .addrep-contentdiv label {
        width: 100%;
        text-align: left;
    }
    .addrepfieldinput{
        width: 100%;
    }
    .addrep-contentdivmob{
        display: flex;
    }
    .addrep-contentdivmob label{
        width: 45%;
        margin-right: 10px;
    }
    .hspbanerInputmob{
        width: 72px;
    }
    .addbanerhspfieldinput {
        width: 100%;
    }
    .addbannerhsp-contentdiv label{
        width: 100%;
        text-align: left;
    }
    .addbannerhsp-contentdiv {
        width: 100%;
    }
    .addbanr-hspimgaeuploadDiv {
        width: 30%;
    }
    .addbannerhsp-contentdiv .react-datepicker-wrapper{
        width: 100%;
    }
    .addbanerhsp-datepickr{
        width: 100% !important;
    }
    .addbannerhsp-contentdiv-check {
        width: 48% !important;
    }
    .addbannerhsp-contentdiv-check label{
        width: 37%;
    }
    .addbannerhsp-contentdiv-upload {
        width: 50%;
    }
    .label-text-siteMob{
        width: 45% !important;
    }
    .label-text-siteimg-mob{
        width: 40% !important;
    }
    .sitegrphsp-input{
        width: 100% !important;
        padding-left: 30px !important;
    }
    .grpsiteicon-addmob{
        width: 10% !important;
    }
    .popup-contentDiv {
        width: 100%;
        top: 50%;
    }
    .qualifcatin-popupInput-promomob{
        width: 233px;
    }
    .specilistn-popupInput-mob{
        width: 75%;
    }
    .special-popuptextareawidth {
        width: 75%;
    }
    .tmelabel-hspadmin{
        width: 89px !important;
    }
    .attchment-adddiv {
        width: 43%;
    }
    .attachment-cancelImg {
        right: 12px;
    }
    .label-smssetngbold {
        width: 60% !important;
    }
    .hspinput-mobdoctr{
        width: 70% !important;
    }
    .hspofce-labelmob{
        text-align: left !important;
    }
    .lanlongdiv {
        margin-top: 34%;
    }
    .hspworkdetails-wrappermargin {
        margin-top: 85%;
    }
    .adrsmap-hspadmin {
        /* width: 90% !important; */
        /* height: 7% !important; */
        right: 11%;
        margin-top: 64%;
    }
    .lanlongdiv {
        margin-top: 47%;
        padding-left: 7px;
    }
    .adrsmap-hspinput{
        position: unset;
        padding-left: 7px;
    }
    .mapinput-marginhsp {
        margin-left: 0px !important;
    }
    .doctr-wraper-border{
        padding: 5px;
    }
    .hspoffcehrDay-widthfromto select{
        width: 100px !important;
    }
    .smssetngsTop-select select{
        width: 70%;
    }
    .popuplabel-widthhspmob{
        width: 100%;
        text-align: left;
    }
    .popupinput-widthproduct {
        width: 100%;
    }
    .healthifo-padng-mob{
        padding: 15px;
    }
    .healthinfo-fielddiv{
        padding-left: 15px;
        padding-right: 15px;
    }
    .helathinfo-tag-mob{
        padding: 0;
    }
    .checkbox-label-healthinfo label{
        width: 30%;
    }
    .date-healthinfo-header{
        width: 80px;
    }
    .date-healthinfo-header input{
        padding: 0 !important;
    }
    .tableHeader-healthifo{
        height: 95px !important;
    }
    .tble-dctrpanel-mob{
        height: 100% !important;
        padding: 20px 0;
    }
    .prcdre-padngmob{
        padding: 10px;
    }
    .searchkeymob-width{
        width: 170px;
    }
    .slecthsp {
        width: 90px;
    }
    .hspinfo-contentdiv-langlog .input-suggestion{
        font-size: 13px;
        text-align: left;
        width: 100%;
        left: 0;
        margin-left: 0;
        margin-top: 0;
    

    }
    .hspworkdetails-wrapper-margintop {
        margin-top: 165px;
    }
    .auto-complete-wrapper {
        width: 100%;
    }
    .custom_popupstyle .modal-dialog{
        max-width: 80%;
        margin: 10px auto;
    }
    .custom_popupstyle .popuplabel-widthhspmob{
        width: 95px;
    }
    .invisible-label{
        display: none;
    }
    .search-btn-report{
        width: unset;
        margin-left: auto;
        margin-top: 0;
    }
    .div-padding-right{
        padding-right: 15px;
      }
      .blog-inner-head,.news-inner-head,.news-head-inner{
        font-size: 18px;
      }
      .ph-main-details li{
        font-size: 16px;
      }
      .ph-newsletter{
        font-size: 22px;
        padding-top: 0;
      }
      .news-details-img{
        width: 100%;
      }
      .ph-news-admin{
        display: flex;
        flex-direction: column;
      }
      .ph-news-main-head{
        padding-bottom: 0;
      }
      .ph-news-admin{
        padding-top: 50px;
      }
      .ph-news-letter-mobile{
        margin-top: 30px;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 0px 7px 0 rgb(133 121 121 / 20%), 0 1px 6px 0 rgb(153 139 139 / 19%);
        margin-bottom: 10px;
      }
      
}

@media (max-width: 670px) {
    .invite-network-banner {
        height: 35vh;
    }
    .pharmacyapp-wrapper .slick-dots {
        bottom: 5px;
    }
    .sp-banner-img {
        /* height: 23vh; */
        object-fit: contain;
    }
    .pharmacyapp-wrapper .sp-banner-content-p {
        font-size: 13px;
        padding-top: 4px;
    }
    .sp-banner-img-div{
        width: 100%;
        }
    .pharmacy-splash-button-r{
        font-size: 12px;
    }
    .sp-notNow-text{
        font-size: 12px;
    }
    .pharmacyapp-splashhead{
        margin-bottom: 10px;
        font-size: 21px;
    }
    .pharmacyapp-wrapper, .pharmacyapp-wrapper p {
        font-size: 13px;
        margin-bottom:6px;
        margin-bottom: 17px;
    }
    .feature-apphead {
        font-size: 16px;
        margin-bottom: 17px;
    }
    .pharmacyapp-splash-freebtn{
        font-size: 12px;
    }
    .pharmacy-splash-btnmain{
        margin-top: 28px;
    }
    .sp-banner-content {
        margin: 30px auto 0;
    }
    .splash-banner {
        padding: 0px 0px;
    }
    .freecms_sliderright{
        padding-bottom: 110px;
    }
    .freecms_container {
        padding-bottom: 30px;
    }
    .form-control {
        font-size: 14px;
    }

}
@media (max-width:570px) {
    .optionAddDiv .form-control{
        width: 90% !important;
    }
    .btn-default {
        font-size: 12px;
        padding: 3px 5px;
    }
    .textwrappergroup {
        display: block;
        width: 100%;
        margin: 0;
    }
    .serviceWrap ul li {
        width: 100%;
        margin: 10px 0;
    }
    .dashboard-right {
        padding: 10px;
    }
    .freecms_apponint_head, .freecms_apponint_para{
        font-size: 14px;
    }
    .freecms_sliderright{
        padding-bottom: 0px;
    }
    .freecms_container {
        padding-bottom: 150px;
    }
    .freecms_left_user {
        width: 50%;
    }
    .ph_siteedit .img-fluid {
        margin-bottom: 6px;
    }
    .addbannerhsp-contentdiv-upload {
        width: 48%;
    }
    .hspmngInput {
        width: 100px;
    }
}



@media (max-width: 360px) {
    .smssetngCheckbox .smsItem label {
        width: 73%;
    }
    /* .hsptimefieldDivMob-time label {
       width: 40px;
    } */
    .hsptimefieldDivMob-time select {
        width: 60px;
    }
    .serviceslabelHspMob {
        width: 45px !important;
    }
    .hspmngInput {
        width: 70px;
    }
    .feature-apphead {
        font-size: 15px;
    }
    
    .sp-banner-content {
        width: 300px;
    }
    .pharmacyapp-wrapper .sp-banner-content-p {
        font-size: 12px;
        margin-bottom: 0px !important;
    }
    .pharmacy-splash-button-r{
        font-size: 12px;
    }
    .sp-notNow-text{
        font-size: 12px;
    }
    .pharmacyapp-splashhead{
        margin-bottom: 5px;
        font-size: 16px;
    }
    .pharmacyapp-splash-freebtn{
        font-size: 12px;
    }
    .pharmacy-splash-btnmain{
        margin-top: 15px;
    }
    .splash-banner {
        padding: 20px 0px;
    }
    .freecms_sliderright{
        padding-bottom: 20px;
    }
    .freecms_container {
        padding-bottom: 130px;
    }
    .freecms_container {
        padding-bottom: 50px;
    }
   
}

@media (max-width: 375px){
    .lanlongdiv {
        margin-top: 53%;
    }
    .hspworkdetails-wrappermargin {
        margin-top: 98%;
    }
    .adrsmap-hspadmin {
        /* width: 88% !important; */
        margin-top: 70%;
        right: 10%;
    }
    .hspmngInput {
        width: 70px;
    }
}

@media (max-width: 320px) {
    .sp-banner-content {
        margin: 12px auto 0;
    }
    .feature-apphead{
        font-size: 15px;
    }
    .sp-banner-content {
        width: 300px;
    }
    .pharmacyapp-wrapper .sp-banner-content-p {
        font-size: 11px;
        padding-top: 0;
        margin-bottom: 0px !important;
    }
    .pharmacy-splash-button-r{
        font-size: 10px;
        padding: 3px 7px !important;
        border-radius: 7px !important;

    }
    .sp-notNow-text{
        font-size: 10px;
    }
    .pharmacyapp-splashhead{
        margin-bottom: 4px;
        font-size: 18px;
    }
    .pharmacyapp-wrapper, .pharmacyapp-wrapper p{
        font-size: 11px;
        margin-bottom: 13px;
    }
    .pharmacyapp-splash-freebtn{
        font-size: 10px;
        border-radius: 3px;
        padding: 1px 10px;
    }
    .pharmacy-splash-btnmain{
        margin-top: 13px;
    }
    .splash-banner {
        padding: 15px 0px;
    }
    .sp-notNow-div {
        margin-top: 4px;
    }
    .freecms_sliderright{
        padding-bottom: 0px;
    }
    .qualifcatin-popupInput {
        width: 138px;
    }
    .slecthspMob {
        width: 90px;
    }

    .addrep-contentdivmob label {
        width: 60%;
    }
    .addbannerhsp-contentdiv-check {
        width: 60% !important;
    }
    .addbannerhsp-contentdiv-upload {
        width: 60%;
    }
    .hspinput-mobdoctr {
        width: 60% !important;
    }
    .adrsmap-hspadmin {
        /* width: 87% !important; */
        margin-top: 82%;
    }
    .hspworkdetails-wrappermargin {
        margin-top: 100%;
    }
   

}